import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import './assets/style.css'
import common from './common/common.js'
import * as Icons from '@ant-design/icons-vue'

import { Avatar, Button, Card, Checkbox, Col, ConfigProvider, DatePicker, Descriptions, Divider, Drawer, Dropdown, Form, Grid, Input, InputNumber, Layout,Notification, List, Menu, message, Modal, Pagination, Progress, Radio, Result, Row, Select, Steps, Switch, Table, Tabs, Tag, Timeline, Tree, Upload } from 'ant-design-vue'



const app = createApp(App)
app.use(Button)
app.use(Layout)
app.use(Menu)
app.use(Tabs)
app.use(Dropdown)
app.use(ConfigProvider)
app.use(Input)
app.use(Grid)
app.use(Col)
app.use(Row)
app.use(Avatar)
app.use(Table)
app.use(Select)
app.use(DatePicker)
app.use(Pagination)
app.use(Drawer)
app.use(Descriptions)
app.use(Form)
app.use(Card)
app.use(Timeline)
app.use(Radio)
app.use(Checkbox)
app.use(Tag)
app.use(Divider)
app.use(Modal)
app.use(Switch)
app.use(InputNumber)
app.use(Upload)
app.use(Timeline)
app.use(Steps)
app.use(message)
app.use(Result)
app.use(Tree)
app.use(Progress)
app.use(List)
app.use(Tag)
app.use(Notification)
// 图标
for (let iconName in Icons){
    app.component(iconName, Icons[iconName])
}

// 配置全局限制方法
app.config.globalProperties.$noMulClicks = common.noMulClicks

// 全局变量
axios.defaults.baseURL = "https://mzf.13524.net"
app.config.globalProperties.$http =axios


app.use(router);
app.mount('#app')

// createApp(App).use(router).mount('#app')
