<template>
    <div>
        <a-tabs  animated :size="size">
            <a-tab-pane key="1" tab="网站信息" class="person-wrap">
                <!-- <h2 class="person-title">基本信息</h2> -->
                <a-form
                    :model="system.data"
                    name="basic"
                    :label-col="{ span: 3 }"
                    :wrapper-col="{ span: 8 }"
                    autocomplete="off"
                    style="width:80%"
                >
                    <a-form-item label="网站名称" >
                        <a-input v-model:value="system.data.title" placeholder="网站名称"/>
                    </a-form-item>

                    <!-- <a-form-item label="Logo" >
                        <a-upload
                            v-model:file-list="fileList"
                            name="file"
                            list-type="picture-card"
                            class="avatar-uploader logoUpload"
                            :headers="headers"
                            :show-upload-list="false"
                            action="https://chatapi.13524.net/admin/addlogfile"
                            @change="({ file }) => handleChangeImg(file)"
                        >
                            <img v-if="system.data.log_url" :src="'https://chatapi.13524.net/'+system.data.log_url" style="width:168px;height:32px" alt="avatar" />
                            <div v-else>
                                <loading-outlined v-if="loading"></loading-outlined>
                                <plus-outlined v-else></plus-outlined>
                                <div class="ant-upload-text">图片上传</div>
                            </div>
                            
                        </a-upload>
                        <span>（图片大小为168*32）</span>
                    </a-form-item> -->
                   <a-form-item label="初始速度" >
                        <a-input-group compact>
                            <!-- <a-input  type="number" placeholder="初始速度" v-model:value="system.data.initial_speed" style="width: 50%" /> -->
                            <a-select  v-model:value="system.data.initial_speed">
                                <a-select-option value="1">低速</a-select-option>
                                <a-select-option value="2">中速</a-select-option>
                                <a-select-option value="3">高速</a-select-option>
                            </a-select>
                        </a-input-group>
                    </a-form-item>
                    <a-form-item label="6分钟挑战" >
                        <a-radio-group v-model:value="system.data.is_6minkg">
                        <a-radio :value="1">开启</a-radio>
                        <a-radio :value="0">关闭  </a-radio>
                    </a-radio-group>
                    </a-form-item>
                    <a-form-item label="默认步距" >
                        <a-input v-model:value="system.data.step_distance" placeholder="默认步距"/>
                    </a-form-item>
                    <a-form-item label="计算步率间隔" >
                        <a-input v-model:value="system.data.step_interval" placeholder="计算步率间隔"/>
                    </a-form-item>
                    <a-form-item label="中速区间1" >
                        <a-input v-model:value="system.data.step_rate1" placeholder="中速区间1"/>
                    </a-form-item>
                    <a-form-item label="中速区间2" >
                        <a-input v-model:value="system.data.step_rate2" placeholder="中速区间2"/>
                    </a-form-item>
                    <a-form-item :wrapper-col="{ offset: 3, span: 16 }">
                        <a-button @click="toSubmit" type="primary" >修改配置</a-button>
                    </a-form-item>
                </a-form>
            </a-tab-pane>
        </a-tabs>
    </div>
</template>

<script>
import { getCurrentInstance , reactive,ref} from 'vue'
import { message} from 'ant-design-vue';
export default {
    data(){
        return{
            size:"large"
        }
    },
    setup(){
        const { proxy } = getCurrentInstance()
        let system=reactive({data:{title:"",initial_speed:"",step_distance:"",step_interval:"d",step_rate1:"d",step_rate2:"d",is_6minkg:""}})

         // 详情
        let getSystem=function(){
            proxy.$http.post('/admin/systemsetpage',{id:1},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    system.data=res.data.data.data
                    system.data.initial_speed=system.data.initial_speed.toString()
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getSystem()



        // 上传logo
        let loading=ref(false)
        let headers=reactive({'token':sessionStorage.getItem("token")})
         let handleChangeImg=function(info){
          
            if (info.status === 'uploading') {
                loading.value = true;
                return;
            }else if(info.status === 'done'){
                loading.value = false;
                console.log(info)
                system.data.log_url=info.response.data.file_src
            }
        }

        // 修改配置
        let toSubmit=function(){
            proxy.$http.post('/admin/updatesystemset',{id:1,title:system.data.title
            ,initial_speed:system.data.initial_speed,step_distance:system.data.step_distance
            ,step_interval:system.data.step_interval,step_rate1:system.data.step_rate1,step_rate2:system.data.step_rate2
            ,is_6minkg:system.data.is_6minkg
            },{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    message.success(res.data.msg);
                }else{
                    message.error(res.data.msg);
                }
            });
        }


        return{
            system,toSubmit,
            handleChangeImg,loading,headers
        }
    }
}
</script>