<template>
  <a-layout style="height:100%">
 
    <!-- <a-layout> -->
      <a-layout-header style="background: #fff; padding: 0">
        <section style="display:flex;align-items: center;">
            <div class="logo" >
              <h2>慢阻肺VR调控平台 <i>TM</i></h2> 
             
              <!-- <img src="" alt=""> -->
            </div>
          <div class="top-menu-info">
          

            <menu-unfold-outlined
            :style="{ fontSize: '16px'}"
              v-if="collapsed"
              class="trigger"
              @click="() => (collapsed = !collapsed)"
            />
            <menu-fold-outlined :style="{ fontSize: '16px'}" v-else class="trigger" @click="() => (collapsed = !collapsed)" />


              <div class="main-menu-right">
                <div class="top-account-info">
                  <!-- <div class="communicate-index" @click="callwrap=true">
                    <h2>外呼电话，当前使用：SIP外呼 </h2>
                    <div class="tel-img">
                      <img src="../../static/tel.svg" alt="">
                    </div>
                  </div> -->
                </div>
                <a-dropdown>
                <div style="cursor:pointer">
                  <span>管理员<span class="block"></span>{{username}}<DownOutlined /></span> 
                </div>
                <template #overlay>
                  <a-menu>
                    <a-menu-item>
                      <a @click="toPerson" href="javascript:;">个人中心</a>
                    </a-menu-item>
                    <a-menu-item>
                      <a  @click="toLoginout" href="javascript:;">退出登录</a>
                    </a-menu-item>
                  </a-menu>
                </template>
              </a-dropdown>
              <img class="head-img" src="../../static/head.png" alt="">

              <!-- <FileSearchOutlined /> -->
              </div>
            
          </div>
        </section>
      
       

        <!-- <a-tabs class="main-tabs" hide-add v-model:activeKey="activeKey"  type="editable-card" @edit="handleClose">
            <a-tab-pane  v-for="tab in tabList.arr" :key="tab.title"  @click="changeMenu(tab)" :tab="tab.title" :closable="tab.url !== '/index'">
          </a-tab-pane>
        </a-tabs> -->
      </a-layout-header>

      <a-layout>
           <a-layout-sider width="200" v-model:collapsed="collapsed" :trigger="null" collapsible style="background: #3ea03f">
            
            <a-menu style="margin-top:20px" v-model:selectedKeys="selectedKeys" :theme="theme" mode="inline">
              <a-menu-item :key="0" @click="toRouter('首页','/index')">
                  <!-- <{{item.icon}} />  -->
                  <home-filled />
                  <span>首页</span>
                </a-menu-item>
              <template v-for="item in menulist.arr">
                <a-sub-menu :key="item.id" v-if="item.children">
                  
                  <template #title>
                    <span>
                      <component :is="item.icon" />
                      <!-- <user-outlined /> -->
                      <span>{{item.title}}</span>
                    </span>
                  </template>
                  <a-menu-item @click="toRouter(option.title,option.href)" v-for="option in item.children" :key="option.id">{{option.title}}</a-menu-item>
                </a-sub-menu>

                <a-menu-item :key="item.id" v-else @click="toRouter(item.title,item.href)">
                  <!-- <{{item.icon}} />  -->
                  <component :is="item.icon" />
                  <span>{{item.title}}</span>
                </a-menu-item>
              </template>

            </a-menu>
          </a-layout-sider>

          <a-layout-content :style="{ margin: '24px 16px',  background: '#fff', minHeight: '280px',overflowY:'auto',overflowX:'hidden' }" >
            <router-view/>
          </a-layout-content>
      
      </a-layout>
        <!-- <a-layout-footer style="text-align: center">
            Ant Design ©2018 Created by Ant UED
          </a-layout-footer> -->
    <!-- </a-layout> -->
  </a-layout>

</template>
<script>
import {UserOutlined,VideoCameraOutlined,UploadOutlined,MenuUnfoldOutlined,MenuFoldOutlined,DownOutlined,FileSearchOutlined} from '@ant-design/icons-vue';
import { defineComponent, reactive, ref } from 'vue';
import {useRouter} from "vue-router";
import { getCurrentInstance } from 'vue'
export default defineComponent({
  components: {
    UserOutlined,VideoCameraOutlined,UploadOutlined,MenuUnfoldOutlined,MenuFoldOutlined,DownOutlined,FileSearchOutlined,
    
  },
  setup() {
    const router=useRouter()
    const { proxy } = getCurrentInstance()
    let menulist=reactive({arr:[]})

    // 获取菜单
    let getMenu=function(){
      let roleid= sessionStorage.getItem("roleid")
      if(roleid==0){
        roleid=""
      }
      proxy.$http.post('/admin/nodelist',{menu_status:1},{
          headers:{
            'token':sessionStorage.getItem("token")
          }
        }).then((res) => {
            if(res.data.status==200){
               menulist.arr=res.data.data
            }else{
              message.error(res.data.msg);
            }
        });
    }
    getMenu()
    // 链接，添加tabs
    var tabList=reactive({arr:[{title:"首页",url:"/index"}]})
    var activeKey=ref("首页")
    let toRouter=function(title,url){
      var flag=0
      for(var i in tabList.arr){
          if(title==tabList.arr[i].title){
              activeKey.value=tabList.arr[i].title
              flag=1
          }
      }
      if(flag===0){
          var tab={title:"",url:""}
          tab.title=title
          tab.url=url
          tabList.arr.push(tab)
          activeKey.value = title;
      }
      router.push({path:url});
    }

    let changeMenu=function(item){
            console.log(item)
            if(item.title==activeKey.value){
                return
            }
            // activemenu=item.url
            activeKey=item.title
            router.push({ path: item.url })
        }
    let handleClose=function(index){
      var num=0
      for(var i in tabList.arr){
          if(index==tabList.arr[i].title){
            num=i
          }
      }
      var length=tabList.arr.length
      if(length === 2){
          activeKey.value="首页"
          tabList.arr.splice(num,1)
          router.push({ path:'/index'})
          return
      }
      length=length-1
      if (num === length) {
        tabList.arr.splice(num,1)
        activeKey.value=tabList.arr[num].title
        router.push({ path: tabList.arr[num].url })
      } else {
        tabList.arr.splice(num,1)
        activeKey.value=tabList.arr[num-1].title
        router.push({ path: tabList.arr[num-1].url })
      }
    }

    let toLoginout=function(){
      sessionStorage.setItem("token","")
      router.push({path:"/login"})
    }

    let toPerson=function(){
      router.push({path:"/person"})
    }

    let username=ref(sessionStorage.getItem("username"))
   
    return {
      username,
      toLoginout,toPerson,
      handleClose,changeMenu,toRouter,tabList,activeKey,
      theme:ref("dark"),
      selectedKeys: ref(['1']),
      collapsed: ref(false),
      menulist
    };
  },
});
</script>
<style>
#components-layout-demo-custom-trigger .trigger {font-size: 18px;line-height: 64px;padding: 0 24px;
  cursor: pointer;transition: color 0.3s;
}
.main-tabs .ant-tabs-tab{border: none !important;background: #fff !important;border-radius: 6px 6px 0 0!important;}
.main-tabs .ant-tabs-tab-active{background: #f0f2f5 !important;}
/* .main-tabs .ant-tabs-tab-active{background: #f5fef6!important;} */
#components-layout-demo-custom-trigger .trigger:hover {color: #1890ff;}
/* .ant-layout-header{height: 102px;} */
.ant-layout{background: #f5fef9!important;}
.logo {height: 64px;background:#fff;padding: 16px;width: 200px;}
.site-layout .site-layout-background {background: #fff;}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
  background-color: #e3fced;
}

</style>