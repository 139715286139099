<template>
    <div>
        <a-card title="知识问答" :bordered='false'>
            <div class="btn-wrap">
                <div class="search-list">
                    <a-button type="primary" @click="openAdd"><template #icon><plus-outlined /></template>添加问答</a-button>
                    <a-button type="danger" @click="delMore"><template #icon><delete-outlined /></template>删除</a-button>
                </div>
                <div>
                    <a-input-group compact>
                        <a-input v-model:value="search"  placeholder="请输入问答标题"/>
                        <a-button type="primary" @click="toSearch">搜索</a-button>
                    </a-input-group>
                </div>
            </div>

            <a-table row-key="id" :pagination="false" :columns="columns" :data-source="dataList.arr" bordered :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
                <template #bodyCell="{ column,record}">
                    <template v-if="column.dataIndex === 'type'">
                        <span v-if="record.type==1">单选</span>
                        <span v-if="record.type==2">多选</span>
                        <span v-if="record.type==3">判断</span>
                        <span v-if="record.type==4">排序</span>
                        <span v-if="record.type==5">简答</span>
                    </template>
                    <template v-if="column.dataIndex === 'state'">
                        <!-- {{record.state}} -->
                        <a-switch @change="checked => changeStatus(checked, record.id)" v-model:checked="record.state" :checkedValue="1" :unCheckedValue="0" checked-children="发布" un-checked-children="审核" />
                    </template>
                    <template v-if="column.dataIndex === 'content'">
                       <video width="120" v-if="record.content&&record.type0==1" :src="record.content"></video>
                       <img v-if="record.content&&record.type0==2" :src="record.content" alt="">
                    </template>
                    <template v-if="column.dataIndex === 'operation'">
                        <a-button type="link" @click="openEdit(record.id)">编辑</a-button>
                        <a-button type="link" @click="del(record.id)">删除</a-button>
                    </template>
                </template>
            </a-table>

            <a-pagination
                v-model:pageSize="page.pageSize"
                show-size-changer
                :total="page.total"
                @change="pageChange"
                :show-total="total => `共 ${total} 条数据`"
            />
        </a-card>

        <!-- 添加、编辑问答 -->
         <a-drawer
            v-model:visible="visible"
            class="custom-class"
            :title="title"
            placement="right"
            width="40%"
        >
         <template #extra>
            <a-button style="margin-right: 8px" @click="visible=false">取消</a-button>
            <a-button type="primary" @click="handleOk">确定</a-button>
            </template>
             <a-form
                :model="formData.data"
                :label-col="{ span: 3 }"
                :wrapper-col="{ span: 16 }"
                autocomplete="off"
            >
                <a-form-item label="题干" name="username" >
                    <a-input placeholder="题干" v-model:value="formData.data.title" />
                </a-form-item>
                <a-form-item label="题目内容" name="username" >
                    <a-radio-group v-model:value="formData.data.type0">
                        <a-radio :value="1">视频</a-radio>
                        <a-radio :value="2">图片  </a-radio>
                    </a-radio-group>
                </a-form-item>

                <a-form-item :wrapper-col="{ offset: 3, span: 16 }" v-if="formData.data.type0==2">
                    <a-upload
                        v-model:file-list="fileList"
                        name="file"
                        list-type="picture-card"
                        class="avatar-uploader"
                        :headers="headers"
                        :show-upload-list="false"
                        action="https://mzf.13524.net/admin/addanimationaqfile"
                        @preview="handlePreview"
                        @change="handleChangeImgtype"
                    >
                        <img v-if="formData.data.content" :src="formData.data.content" style="width:128px;height:128px" alt="avatar" />
                        <div v-else>
                            <loading-outlined v-if="loading"></loading-outlined>
                            <plus-outlined v-else></plus-outlined>
                            <div class="ant-upload-text">图片上传</div>
                        </div>
                    </a-upload>
                </a-form-item>
                <a-form-item :wrapper-col="{ offset: 3, span: 16 }" v-if="formData.data.type0==1">
                    <a-upload
                        v-model:file-list="fileList"
                        name="file"
                        list-type="picture-card"
                        class="avatar-uploader"
                        :headers="headers"
                        :show-upload-list="false"
                        action="https://mzf.13524.net/admin/addanimationaqfile"
                        @preview="handlePreview"
                        @change="handleChangeAudiotype"
                    >
                        <!-- <img v-if="item.content" :src="item.content" alt="avatar" /> -->
                        <video v-if="formData.data.content" width="128" :src="formData.data.content" controls="controls" ref="video">
                                您的浏览器不支持播放该视频！
                            </video>
                        <div v-else>
                            <loading-outlined v-if="loading"></loading-outlined>
                            <plus-outlined v-else></plus-outlined>
                            <div class="ant-upload-text">视频上传</div>
                        </div>

                        <a-modal v-model:visible="videoPreviewVisible" :footer="null"  @cancel="videoPreviewVisible=false" >
                            <video :src="videosrc" width="100%" height="600px;"  controls="controls" ref="video">
                                您的浏览器不支持播放该视频！
                            </video>
                        </a-modal>
                    </a-upload>
                </a-form-item>

                <a-form-item label="题型" name="username" >
                    <a-radio-group v-model:value="formData.data.type">
                        <a-radio :value="1">单选题</a-radio>
                        <a-radio :value="2">多选题</a-radio>
                        <a-radio :value="3">判断题</a-radio>
                        <a-radio :value="4">排序题</a-radio>
                        <a-radio :value="5">简答题</a-radio>
                    </a-radio-group>
                </a-form-item>

                <a-form-item label="排序" name="sort" >
                    <a-input placeholder="排序，数字越小越靠前" v-model:value="formData.data.sort" />
                </a-form-item>

                <a-form-item label="是否发布" name="username" >
                    <a-switch v-model:checked="formData.data.state" :checkedValue="1" :unCheckedValue="0" checked-children="发布" un-checked-children="审核" />
                </a-form-item>

                <a-divider orientation="left">编辑题目</a-divider>

                <template v-for="item,index in formData.data.option" :key="index">
                    <a-form-item :label="changeNum(index)" class="flex-del-title">
                        <a-radio-group v-model:value="item.type">
                            <a-radio :value="1">文字</a-radio>
                            <a-radio :value="2">图片</a-radio>
                            <a-radio :value="4">视频</a-radio>
                        </a-radio-group>
                     

                        <a-button type="danger" size="small" shape="circle" @click="delItem(index)"><template #icon><minus-outlined /></template></a-button>
                    </a-form-item>

                       <!-- {{item.type}} -->
                    <a-form-item :wrapper-col="{ offset: 3, span: 16 }" v-if="item.type==1">
                        <a-input placeholder="内容" v-model:value="item.content" />
                    </a-form-item>
                    <a-form-item :wrapper-col="{ offset: 3, span: 16 }" v-if="item.type==2">
                        <a-upload
                            v-model:file-list="fileList"
                            name="file"
                            list-type="picture-card"
                            class="avatar-uploader"
                            :headers="headers"
                            :show-upload-list="false"
                            action="https://mzf.13524.net/admin/addanimationaqfile"
                            @preview="handlePreview"
                            @change="({ file }) => handleChangeImg(file, index)"
                        >
                            <img v-if="item.content" :src="item.content" style="width:128px;height:128px" alt="avatar" />
                            <div v-else>
                                <loading-outlined v-if="loading"></loading-outlined>
                                <plus-outlined v-else></plus-outlined>
                                <div class="ant-upload-text">图片上传</div>
                            </div>
                        </a-upload>
                    </a-form-item>
                    <a-form-item :wrapper-col="{ offset: 3, span: 16 }" v-if="item.type==4">
                        <a-upload
                            v-model:file-list="fileList"
                            name="file"
                            list-type="picture-card"
                            class="avatar-uploader"
                            :headers="headers"
                            :show-upload-list="false"
                            action="https://mzf.13524.net/admin/addanimationaqfile"
                            @preview="handlePreview"
                            @change="({ file }) => handleChangeAudio(file, index)"
                        >
                            <!-- <img v-if="item.content" :src="item.content" alt="avatar" /> -->
                            <video v-if="item.content" :src="item.content" width="128" height="128"  controls="controls" ref="video">
                                    您的浏览器不支持播放该视频！
                                </video>
                            <div v-else>
                                <loading-outlined v-if="loading"></loading-outlined>
                                <plus-outlined v-else></plus-outlined>
                                <div class="ant-upload-text">视频上传</div>
                            </div>

                            <a-modal v-model:visible="videoPreviewVisible" :footer="null"  @cancel="videoPreviewVisible=false" >
                                <video :src="videosrc" width="100%" height="600px;"  controls="controls" ref="video">
                                    您的浏览器不支持播放该视频！
                                </video>
                            </a-modal>
                        </a-upload>
                    </a-form-item>
                </template>
                <a-form-item :wrapper-col="{ offset: 3, span: 16 }" >
                    <a-button type="primary" @click="addItem"><template #icon><plus-outlined /></template>添加题目</a-button>
                </a-form-item>
                
                <a-form-item label="题目答案" v-if="formData.data.type==1">
                    <a-radio-group v-model:value="formData.data.answer">
                        <a-radio v-for="item,index in optionList.arr" :key="index" :value="index">{{changeNum(index)}}</a-radio>
                    </a-radio-group>
                </a-form-item>

                <a-form-item label="题目答案" v-if="formData.data.type==2">
                    <a-checkbox-group v-model:value="formData.data.answer">
                        <a-checkbox  v-for="item,index in optionList.arr" :key="index" :value="index">{{changeNum(index)}}</a-checkbox >
                    </a-checkbox-group>
                </a-form-item>

                <a-form-item label="题目答案" v-if="formData.data.type==3">
                    <a-radio-group v-model:value="formData.data.answer">
                        <a-radio :value="1">是</a-radio>
                        <a-radio :value="2">否</a-radio>
                    </a-radio-group>
                </a-form-item>

                <a-form-item label="题目排序" v-if="formData.data.type==4">
                    <a-input v-model:value="formData.data.answer" placeholder="请输入题目顺序，用英文逗号间隔" />
                </a-form-item>

                <a-form-item label="题目解析" v-if="formData.data.type==5">
                    <a-textarea v-model:value="formData.data.answer" placeholder="题目解析" :rows="4" />
                </a-form-item>

                <section class="two-video">

                    <a-form-item label="正确视频"  :label-col="{ span: 6 }">
                        <a-upload
                            v-model:file-list="fileList"
                            name="file"
                            list-type="picture-card"
                            class="avatar-uploader"
                            :headers="headers"
                            :show-upload-list="false"
                            action="https://mzf.13524.net/admin/addanimationaqfile"
                            @preview="handlePreview"
                            @change="handleChangeAudioCurrent"
                        >
                            <!-- <img v-if="item.content" :src="item.content" alt="avatar" /> -->
                            <video v-if="formData.data.true_to_url" width="128" :src="formData.data.true_to_url" controls="controls" ref="video">
                                    您的浏览器不支持播放该视频！
                                </video>
                            <div v-else>
                                <loading-outlined v-if="loading"></loading-outlined>
                                <plus-outlined v-else></plus-outlined>
                                <div class="ant-upload-text">视频上传</div>
                            </div>

                            <a-modal v-model:visible="videoPreviewVisible" :footer="null"  @cancel="videoPreviewVisible=false" >
                                <video :src="videosrc" width="100%" height="600px;"  controls="controls" ref="video">
                                    您的浏览器不支持播放该视频！
                                </video>
                            </a-modal>
                        </a-upload>
                    </a-form-item>

                    <a-form-item label="错误视频" :label-col="{ span: 6 }" >
                        <a-upload
                            v-model:file-list="fileList"
                            name="file"
                            list-type="picture-card"
                            class="avatar-uploader"
                            :headers="headers"
                            :show-upload-list="false"
                            action="https://mzf.13524.net/admin/addanimationaqfile"
                            @preview="handlePreview"
                            @change="handleChangeAudioError"
                        >
                            <!-- <img v-if="item.content" :src="item.content" alt="avatar" /> -->
                            <video v-if="formData.data.fail_to_url" width="128" :src="formData.data.fail_to_url" controls="controls" ref="video">
                                    您的浏览器不支持播放该视频！
                                </video>
                            <div v-else>
                                <loading-outlined v-if="loading"></loading-outlined>
                                <plus-outlined v-else></plus-outlined>
                                <div class="ant-upload-text">视频上传</div>
                            </div>

                            <a-modal v-model:visible="videoPreviewVisible" :footer="null"  @cancel="videoPreviewVisible=false" >
                                <video :src="videosrc" width="100%" height="600px;"  controls="controls" ref="video">
                                    您的浏览器不支持播放该视频！
                                </video>
                            </a-modal>
                        </a-upload>
                    </a-form-item>

                </section>

            </a-form>


        </a-drawer>
    </div>
</template>


<script>
import { reactive,ref,computed } from 'vue';
import {ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getCurrentInstance ,createVNode,watch } from 'vue'
import { message,Modal} from 'ant-design-vue';
const columns = [
    {title: 'ID',dataIndex: 'id',width:90},
    {title: '题型',dataIndex: 'type'},
    {title: '题干',dataIndex: 'title'},
    {title: '题目内容',dataIndex: 'content'},
    {title: '排序',dataIndex: 'sort',width:90},
    {title: '发布',dataIndex: 'state'},
    {title: '添加时间',dataIndex: 'create_time'},
    {title: '操作',dataIndex: 'operation',width:180}
];
export default {
    // computed:{
    //     return index
    // },
    setup(){
        const { proxy } = getCurrentInstance()
        let dataList=reactive({arr:[]})
        let headers=reactive({'token':sessionStorage.getItem("token")})
        
        let page=reactive({pageSize:"10",total:"20",page:"1"})//分页

        // 搜索
        let search=ref()
        let toSearch=function(){
            page.page="1"
            getTabledata()
        }
        // 列表
         let getTabledata=function(){
            proxy.$http.post('/admin/animationaqlist',{page:page.page,pagesize:page.pageSize,search:search.value},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data.data
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()

        let handleChangeAudiotype=function(info){
            if (info.file.status === 'uploading') {
                loading.value = true;
                return;
            }else if(info.file.status === 'done'){
                loading.value = false;
                formData.data.content=info.file.response.data.file_src_oss
            }
        }
        let handleChangeAudioCurrent=function(info){
            if (info.file.status === 'uploading') {
                loading.value = true;
                return;
            }else if(info.file.status === 'done'){
                loading.value = false;
                formData.data.true_to_url=info.file.response.data.file_src_oss
            }
        }
        let handleChangeAudioError=function(info){
            if (info.file.status === 'uploading') {
                loading.value = true;
                return;
            }else if(info.file.status === 'done'){
                loading.value = false;
                formData.data.fail_to_url=info.file.response.data.file_src_oss
            }
        }

        let handleChangeImgtype=function(info){
            if (info.file.status === 'uploading') {
                loading.value = true;
                return;
            }else if(info.file.status === 'done'){
                loading.value = false;
                formData.data.content=info.file.response.data.file_src_oss
            }
        }

        // 上传
        const fileList = ref();
        let loading=ref(false)
        let beforeUpload=function(file,index){
            console.log(file)
            console.log("index="+index)
            // formData.data.file=file.url
        }
        let handleChangeAudio=function(info,index){
          
            if (info.status === 'uploading') {
                loading.value = true;
                return;
            }else if(info.status === 'done'){
                loading.value = false;
                console.log(info)
                // fileList.value=info
                formData.data.option[index].content=info.response.data.file_src_oss
            }
        }

        let handleChangeImg=function(info,index){
          
            if (info.status === 'uploading') {
                loading.value = true;
                return;
            }else if(info.status === 'done'){
                loading.value = false;
                console.log(info)
                // fileList.value=info
                formData.data.option[index].content=info.response.data.file_src_oss
            }
        }

        // 视频预览
        let videoPreviewVisible=ref(false)
        let currentInstance = getCurrentInstance()
        watch(()=>videoPreviewVisible,(val)=>{
            if(val === false){
                let video =  currentInstance.proxy.$refs.video;
                video.pause()
            }
        })
        let videosrc=ref()
        let handlePreview=function(file){
            videoPreviewVisible.value=true
            nextTick(()=>{
                videosrc.value = file.response.data
            })
            return false;
        }


        // 题目

        let itemList=reactive({arr:[{type:1,title:""}]})
        let optionList=reactive({arr:[{option:""}]})
        let addItem=function(){
            formData.data.option.push({type:formData.data.type,content:""})
            optionList.arr.push({option:""})      
        }
        let delItem=function(index){
            formData.data.option.splice(index,1)
            optionList.arr.splice(index,1)
        }
        // 过滤器
        const changeNum = computed(() => {
            return (num) => {
                num++
                let item= "选项"+num
                return item
            };
        })

        // 添加、编辑
        let formData=reactive({data:{title:"",type0:1,content:"",sort:0,type:1,state:1,answer:"",true_to_url:"",fail_to_url:"",option:[{type:1,content:""}]}})
        let title=ref("")
        let visible=ref(false)
        let openAdd=function(){
            formData.data.title=""
            formData.data.type0=1
            formData.data.type=1
            formData.data.state=1
            formData.data.sort=0
            formData.data.answer=""
            formData.data.true_to_url=""
            formData.data.fail_to_url=""
            formData.data.content=""
            itemList.arr=[{type:1,title:""}]
            formData.data.option=[{type:1,content:""}]
            title.value="添加问答"
            visible.value=true
        }

         let openEdit=function(id){
            proxy.$http.post('/admin/animationaqpage',{id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    optionList.arr=[]
                    visible.value=true
                    title.value="编辑问答"
                    formData.data=res.data.data.data
                    if(res.data.data.data.type!=4){
                        formData.data.answer=parseInt(formData.data.answer)
                    }
                    if(res.data.data.data.type==2){
                        formData.data.answer=res.data.data.data.anser.split(",")
                    }
                    for(var i=0;i<formData.data.option.length;i++){
                        optionList.arr.push({option:""})    
                    }
                    
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        let handleOk=function(){
            var arrlist=""
            if(formData.data.type==2&&formData.data.answer.length>0){
                console.log(formData.data.answer)
                arrlist=formData.data.answer.join(",")
                formData.data.answer=arrlist
            }
            
            
            if(title.value=="添加问答"){
                proxy.$http.post('/admin/addanimationaq',{title:formData.data.title,type0:formData.data.type0,type:formData.data.type,
                content:formData.data.content,state:formData.data.state,answer:formData.data.answer,option:formData.data.option,sort:formData.data.sort,
                true_to_url:formData.data.true_to_url,fail_to_url:formData.data.fail_to_url},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }else if(title.value=="编辑问答"){
                proxy.$http.post('/admin/updateanimationaq',{id:formData.data.id,title:formData.data.title,type0:formData.data.type0,type:formData.data.type,
                content:formData.data.content,state:formData.data.state,answer:formData.data.answer,option:formData.data.option,sort:formData.data.sort,
                true_to_url:formData.data.true_to_url,fail_to_url:formData.data.fail_to_url},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }
            
        }

         let changeStatus=function(checked,id){

            proxy.$http.post('/admin/updateanimationaq',{id:id,pid:formData.data.pid,state:checked},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
        }

        // 多选
        let selectedRowKeys=ref()
        let onSelectChange=function(selected) {
            console.log(selected)
            selectedRowKeys.value = selected;
        }

        // 删除
        let delMore=function(){
            var id=selectedRowKeys.value.join(",")
            del(id)
        }
        let del=function(id){
            Modal.confirm({
                title: '你确定要删除吗？',
                icon: createVNode(ExclamationCircleOutlined),
            onOk() {
                proxy.$http.post('/admin/delanimationaq',{ids:id},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            },
            onCancel() {
             console.log('Cancel');
            }
            })
            
        }



        // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
            getTabledata()
        }
        return{
            headers,handlePreview,videoPreviewVisible,beforeUpload,handleChangeAudio,fileList,loading,handleChangeImg,handleChangeAudiotype,handleChangeImgtype,
            handleChangeAudioCurrent,handleChangeAudioError,changeStatus,
            toSearch,search,
            addItem,itemList,optionList,changeNum,delItem,
            visible,formData,openAdd,title,handleOk,openEdit,
            onSelectChange,delMore,del,
            columns,dataList,pageChange,page
        }
    }
}
</script>