<template>
    <div>
        <a-card title="用户信息列表" :bordered='false'>
            <div class="btn-wrap">
                <div class="search-list">
                    <!-- <a-button type="primary" @click="openAdd"><template #icon><plus-outlined /></template>添加患者</a-button> -->
                    <a-button type="danger" @click="delMore"><template #icon><delete-outlined /></template>删除</a-button>
                </div>
                <div>
                    <a-input-group compact>
                        <a-input v-model:value="search"  placeholder="请输入用户姓名"/>
                        <a-button type="primary" @click="toSearch">搜索</a-button>
                    </a-input-group>
                </div>
            </div>

            <a-table :scroll="{x:2460}" rowKey="id" size="small" :pagination="false" :columns="columns" :data-source="dataList.arr" bordered :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
                <template #bodyCell="{ column,record}">
                    <template v-if="column.dataIndex === 'dare_type'">
                        <span v-if="record.dare_type==1">基线测评</span>
                        <span v-if="record.dare_type==2">一级任务</span>
                        <span v-if="record.dare_type==3">二级任务</span>
                        <span v-if="record.dare_type==4">三级任务</span>
                    </template>

                    <template v-if="column.dataIndex === 'dare_state'">
                        <a-tag v-if="record.dare_state==1" color="success">挑战成功</a-tag>
                        <a-tag v-if="record.dare_state==0" color="processing">进行中</a-tag>
                        <a-tag v-if="record.dare_state==2" color="error">挑战失败</a-tag>
                    </template>
                    
                    <template v-if="column.dataIndex === 'operation'">
                        <a-button type="link" @click="openEharts(record.id)">图表</a-button>
                        <a-button type="link" @click="openTable(record.id)">表格</a-button>
                        <!-- <a-button type="link" @click="openEdit(record.id)">编辑</a-button>
                        <a-button type="link" @click="del(record.id)">删除</a-button> -->
                    </template>
                </template>
            </a-table>

            <a-pagination
                v-model:pageSize="page.pageSize"
                show-size-changer
                :total="page.total"
                @change="pageChange"
                :show-total="total => `共 ${total} 条数据`"
            />
        </a-card>

        <!-- 添加、编辑患者 -->
        <a-modal v-model:visible="visible"  :title="title" @ok="handleOk" >
             <a-form
                :model="formData.data"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 16 }"
                autocomplete="off"
            >
                <a-form-item label="姓名" name="username" >
                    <a-input placeholder="姓名" v-model:value="formData.data.username" />
                </a-form-item>

                <a-form-item label="手机号" name="telephone" >
                    <a-input placeholder="手机号" type="tel" v-model:value="formData.data.telephone" />
                </a-form-item>

                <a-form-item label="年龄" name="age" >
                    <a-input placeholder="年龄" v-model:value="formData.data.age" />
                </a-form-item>

                <a-form-item label="性别" name="sex" >
                    <a-radio-group v-model:value="formData.data.sex">
                        <a-radio :value="1">男</a-radio>
                        <a-radio :value="2">女</a-radio>
                    </a-radio-group>
                </a-form-item>
            </a-form>
        </a-modal>


        <!-- 图表 -->
        <a-modal v-model:visible="echartsvisible" title="图表"  width="60%">
            <a-tabs v-model:activeKey="activeKey" @change="changeTab">
                <a-tab-pane key="1" tab="生理指标">
                    <div id="indexCharts" style="width:100%;height:360px;background:#fff;box-size:border-box;padding-top:20px"></div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="呼吸困难评分" force-render>
                    <div id="indexCharts2" style="width:100%;height:360px;background:#fff;box-size:border-box;padding-top:20px"></div>
                </a-tab-pane>
                <a-tab-pane key="3" tab="恐惧">
                    <div id="indexCharts3" style="width:100%;height:360px;background:#fff;box-size:border-box;padding-top:20px"></div>
                </a-tab-pane>
            </a-tabs>
            
        </a-modal>

         <!-- 表格 -->
        <a-modal v-model:visible="tablevisible" title="表格" :footer="null" width="60%">
            <a-button type="primary" style="margin-bottom:20px" @click="exportTable"><template #icon><cloud-download-outlined /></template>导出</a-button>
            <a-table rowKey="id" :pagination="false" :columns="columns2" :data-source="tableList.arr" bordered >
            
            </a-table>
             <a-pagination
                v-model:pageSize="page2.pageSize"
                show-size-changer
                :total="page2.total"
                @change="pageChange2"
                :show-total="total => `共 ${total} 条数据`"
            />

        </a-modal>
    </div>
</template>


<script>
import { nextTick, reactive,ref } from 'vue';
import {ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getCurrentInstance ,createVNode} from 'vue'
import { message,Modal} from 'ant-design-vue';
import * as echarts from 'echarts';
const columns = [
    {title: 'ID',dataIndex: 'id',width:80,fixed: 'left'},
    {title: '用户姓名',dataIndex: 'username_user',width:100,fixed: 'left'},
    {title: '当前任务',dataIndex: 'dare_type',width:110},
    {title: '任务状态',dataIndex: 'dare_state',width:110},
    {title: '步数',dataIndex: 'epicycle_steps',width:90},
    {title: '距离',dataIndex: 'epicycle_distance',width:90},
    {title: '开始心率',dataIndex: 'start_heart_rate',width:120},
    {title: '结束心率',dataIndex: 'end_heart_rate',width:120},
    {title: '最大心率',dataIndex: 'max_heart_rate',width:120},
    {title: '最小心率',dataIndex: 'min_heart_rate',width:120},
    {title: '开始SpO2',dataIndex: 'start_spo',width:120},
    {title: '结束SpO2',dataIndex: 'end_spo',width:120},
    {title: '最高SpO2',dataIndex: 'max_spo',width:120},
    {title: '最低SpO2',dataIndex: 'min_spo',width:120},
    {title: '开始呼吸频率',dataIndex: 'start_RR',width:160},
    {title: '结束呼吸频率',dataIndex: 'end_RR',width:160},
    {title: '最大呼吸频率',dataIndex: 'max_RR',width:170},
    {title: '最小呼吸频率',dataIndex: 'min_RR',width:170},
    {title: '操作员',dataIndex: 'username_admin_user',width:110},
    {title: '开始时间',dataIndex: 'start_time',width:180},
    {title: '结束时间',dataIndex: 'end_time',width:180,},
    {title: '图表',dataIndex: 'operation',width:180,}
];
const columns2=[
    {title: 'SPO2',dataIndex: 'spo'},
    {title: '心率',dataIndex: 'heart_rate'},
    {title: '呼吸频率',dataIndex: 'RR'},
    {title: '时间',dataIndex: 'create_time'},
]
export default {
    setup(){
        const { proxy } = getCurrentInstance()
        let dataList=reactive({arr:[]})
        
        let page=reactive({page:"",pageSize:"10",total:"20"})//分页
        let page2=reactive({page:"",pageSize:"10",total:"20"})//分页

        // 搜索
        let search=ref()
        let toSearch=function(){
            page.page="1"
            getTabledata()
        }

        // 列表
         let getTabledata=function(){
            proxy.$http.post('/admin/dareloglist',{page:page.page,pagesize:page.pageSize,search:search.value},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data.data
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()

        // 表格
        let tableList=reactive({arr:[]})
        let tablevisible=ref(false)
        let tabletid=ref()
        let tablenum=ref()
        let openTable=function(id){
            tabletid.value=id
              proxy.$http.post('/admin/userspolist',{page:page2.page,pagesize:page2.pageSize,dare_log_id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    tablevisible.value=true
                    tableList.arr=res.data.data.user_spo_list.data
                    page2.total=res.data.data.user_spo_list.total
                    tablenum.value=res.data.data.user_spo_list.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        // 导出
        let exportTable=function(){
             proxy.$http.post('/admin/exportuserspolisturl',{
                dare_log_id:tabletid.value,
                rows:tablenum.value
            },{
                headers: {
                    'token':sessionStorage.getItem("token")
                },
            }).then((res)=>{
                // console.log(res.data.result.href)
                let downloadElement = document.createElement('a')
                // 创建下载的链接
                // let href = window.URL.createObjectURL(res.data.result.href)
                downloadElement.style.display = 'none'
                downloadElement.href = res.data.data.href
                // 下载后文件名
                // downloadElement.download = fileName
                document.body.appendChild(downloadElement)
                // 点击下载
                downloadElement.click()
                // 下载完成移除元素
                document.body.removeChild(downloadElement)
                // 释放掉blob对象
                window.URL.revokeObjectURL(res.data.data.href)
            })
        }

        // 图表
        let echartsvisible=ref(false)
        let chartsid=ref()
        let openEharts=function(id){
            chartsid.value=id
             proxy.$http.post('/admin/darelogpage',{id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    echartsvisible.value=true
                    nextTick(()=>{
                        let myChart = echarts.init(document.getElementById('indexCharts'));
                        myChart.setOption({
                            legend: {
                                data: ['SPO2','心率', "呼吸频率"]
                            },
                            tooltip: {
                                trigger: 'axis',
                            },
                            xAxis: {
                                splitLine: {
                                    show: false
                                },
                                data: res.data.data.data.user_spos.times
                            },
                            yAxis:{
                                splitLine: {
                                    show: false
                                }
                            },
                            grid: {top:'17%',left:"6%",right:"6%"},
                            series: [
                                {
                                    name: "SPO2",
                                    type: "line",
                                    smooth: true,
                                    showSymbol: false,
                                    itemStyle:{
                                        normal: {
                                            color:"#409EFC",
                                            lineStyle: {
                                                width:2,
                                                color:"#409EFC"
                                            }
                                        }
                                    },
                                    data: res.data.data.data.user_spos.spos
                                },
                                {
                                    name: "心率",
                                    type: "line",
                                    smooth: true,
                                    showSymbol: false,
                                    itemStyle:{
                                        normal: {
                                            color:"#ec2020",
                                            lineStyle: {
                                                width:2,
                                                color:"#ec2020"
                                            }
                                        }
                                    },
                                    data: res.data.data.data.user_spos.heart_rates
                                },
                                {
                                    name: "呼吸频率",
                                    type: "line",
                                    smooth: true,
                                    showSymbol: false,
                                    itemStyle:{
                                        normal: {
                                            color:"#3ea03f",
                                            lineStyle: {
                                                width:2,
                                                color:"#3ea03f"
                                            }
                                        }
                                    },
                                    data: res.data.data.data.user_spos.RRs
                                }
                            ]
                        });

                        

                    })
                
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        let activeKey=ref("1")
        let changeTab=function(index){
             proxy.$http.post('/admin/darelogpage',{id:chartsid.value},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    if(index=="2"){
                        let myChart2 = echarts.init(document.getElementById('indexCharts2'));
                        myChart2.setOption({
                            legend: {
                                data: ['呼吸困难评分']
                            },
                            tooltip: {
                                trigger: 'axis',
                            },
                            xAxis: {
                                data: res.data.data.data.user_dyspnea.times
                            },
                            yAxis:{},
                            grid: {top:'17%',left:"6%",right:"6%"},
                            series: [
                                {
                                    name: "呼吸困难评分",
                                    type: "line",
                                    smooth: true,
                                    itemStyle:{
                                        normal: {
                                            color:"#409EFC",
                                            lineStyle: {
                                                width:3,
                                                color:"#409EFC"
                                            }
                                        }
                                    },
                                    data: res.data.data.data.user_dyspnea.mate
                                }
                            ]
                        });
                    }else if(index=="3"){
                        let myChart3 = echarts.init(document.getElementById('indexCharts3'));
                        myChart3.setOption({
                            legend: {
                                data: ["恐惧评分"]
                            },
                            tooltip: {
                                trigger: 'axis',
                            },
                            xAxis: {
                                data: res.data.data.data.user_fear.times
                            },
                            yAxis:{},
                            grid: {top:'17%',left:"6%",right:"6%"},
                            series: [
                                {
                                    name: "恐惧评分",
                                    type: "line",
                                    smooth: true,
                                    itemStyle:{
                                        normal: {
                                            color:"#409EFC",
                                            lineStyle: {
                                                width:3,
                                                color:"#409EFC"
                                            }
                                        }
                                    },
                                    data: res.data.data.data.user_fear.mate
                                }
                            ]
                        });
                    }
                }
            })
            
        }

        // 添加、编辑
        let formData=reactive({data:{username:"",telephone:"",age:"",sex:""}})
        let title=ref("")
        let visible=ref(false)
        let openAdd=function(){
            formData.data.username=""
            formData.data.telephone=""
            formData.data.age=""
            formData.data.sex=""
            title.value="添加患者"
            visible.value=true
        }

        let openEdit=function(id){
            proxy.$http.post('/admin/userpage',{id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    visible.value=true
                    title.value="编辑患者"
                    formData.data=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        let handleOk=function(){
            if(title.value=="添加患者"){
                proxy.$http.post('/admin/adduser',{username:formData.data.username,telephone:formData.data.telephone,age:formData.data.age,
                sex:formData.data.sex},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }else if(title.value=="编辑患者"){
                proxy.$http.post('/admin/updateuser',{id:formData.data.id,username:formData.data.username,telephone:formData.data.telephone,age:formData.data.age,
                sex:formData.data.sex},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }
        }
        // 多选
        let selectedRowKeys=ref()
        let onSelectChange=function(selected) {
            selectedRowKeys.value = selected;
        }

         // 删除
        let delMore=function(){
            var id=selectedRowKeys.value.join(",")
            del(id)
        }
        let del=function(id){
            Modal.confirm({
                title: '你确定要删除吗？',
                icon: createVNode(ExclamationCircleOutlined),
            onOk() {
                proxy.$http.post('/admin/deldarelog',{ids:id},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            },
            onCancel() {
                console.log('Cancel');
            }
            })
            
        }



        // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
            getTabledata()
        }
        let pageChange2=function(current, size){
            page2.pageSize=size
            page2.page=current
            openTable(tabletid.value)
        }
        return{
            columns2,tableList,openTable,pageChange2,page2,exportTable,tablevisible,
            openEharts,echartsvisible,changeTab,activeKey,
            toSearch,search,
            visible,formData,openAdd,title,handleOk,openEdit,
            onSelectChange,delMore,del,
            columns,dataList,pageChange,page
        }
    }
}
</script>