<template>
    <div>
        <a-card title="患者列表" :bordered='false'>
            <div class="btn-wrap">
                <div class="search-list">
                    <a-button type="primary" @click="openAdd"><template #icon><plus-outlined /></template>添加患者</a-button>
                    <a-button type="danger" @click="delMore"><template #icon><delete-outlined /></template>删除</a-button>
                </div>
                <div>
                    <a-input-group compact>
                        <a-input v-model:value="search"  placeholder="请输入患者姓名"/>
                        <a-button type="primary" @click="toSearch">搜索</a-button>
                    </a-input-group>
                </div>
            </div>

            <a-table :pagination="false" row-key="id" :columns="columns" :data-source="dataList.arr" bordered :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
                <template #bodyCell="{ column,record}">
                    <template v-if="column.dataIndex === 'sex'">
                        <span v-if="record.sex==1">男</span>
                        <span v-if="record.sex==2">女</span>
                    </template>
                    <template v-if="column.dataIndex === 'VR'">
                        <a-button type="link" @click="openVR(record.telephone,record.username,record.age,record.id)">VR信息</a-button>
                    </template>
                    <template v-if="column.dataIndex === 'operation'">
                        <a-button type="link" @click="openEdit(record.id)">编辑</a-button>
                        <a-button type="link" @click="del(record.id)">删除</a-button>
                    </template>
                </template>
            </a-table>

            <a-pagination
                v-model:pageSize="page.pageSize"
                show-size-changer
                :total="page.total"
                @change="pageChange"
                :show-total="total => `共 ${total} 条数据`"
            />
        </a-card>

        <!-- VR信息  -->
        <a-drawer
            v-model:visible="vrvisible"
            class="vrinfowrap"
            title="VR监控信息"
            placement="right"
            width="40%"
            @close="colsevrinfo"
        >
            <template #extra>
                <a-button style="margin-right: 8px" @click="colse">取消</a-button>
                <!-- <a-button type="primary" @click="handleOkVR">确定</a-button> -->
                <a-button style="background:#1890ff" type="primary" @click="updateVR(vrtel,vrname,vrage)">刷新数据</a-button>
            </template>
            <div class="vrinfotop">
                    <div class="vrtopinfo">
                        <p>当前患者：{{vrname}}</p>
                        <p>手机号码：{{vrtel}}</p>
                        <p>
                            <span>初始距离：</span>
                            <span >{{initial_distance}}</span>
                            <EditOutlined @click="openEditdistance" />
                        </p>
                        <p>最大心率：{{maxxl}}</p>
                    </div>
                    <!-- <p v-if="is_select_video=='1'" style="padding-top:20px;color:#54a6fb;font-weight:600;font-size:16px">请医生播放视频</p> -->
                    <a-divider></a-divider>

                        <div id="indexCharts" style="width:700px;height:280px;background:#fff;box-size:border-box;padding-top:20px"></div>

                        <div class="vrtopinfo">
                            <p>当前行走距离：{{distance}}</p>
                            <p>当前呼吸困难评分：{{hxnum}}</p>
                            <p>当前恐惧评分：{{kjnum}}</p>
                        </div>
                    <a-divider></a-divider>
            </div>
            

            <div class="vrinfobottom">
                <a-form
                :model="formData.data"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 16 }"
                autocomplete="off"
                >

                <a-form-item label="挑战类型" name="dare_type" >
                    <a-radio-group v-model:value="vrData.data.dare_type" @change="daretypeC">
                        <a-radio :value="1">基线测评</a-radio>
                        <a-radio :value="2">一级任务</a-radio>
                        <a-radio :value="3">二级任务</a-radio>
                        <a-radio :value="4">三级任务</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item label="挑战状态" name="dare_state" >
                    <a-radio-group v-model:value="vrData.data.dare_state" @change="darestateC">
                        <a-radio :value="0">进行中</a-radio>
                        <a-radio :value="1">挑战成功</a-radio>
                        <a-radio :value="2">挑战失败</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item label="用户速度" name="speed" >
                    <a-radio-group v-model:value="vrData.data.speed" @change="speedC">
                        <a-radio :value="1">低速 </a-radio>
                        <a-radio :value="2">中速</a-radio>
                        <a-radio :value="3">高速</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item label="呼吸困难分值表" name="dyspnea_Score_table" >
                    <a-radio-group v-model:value="vrData.data.dyspnea_Score_table" @change="scoreC">
                        <a-radio :value="1">显示 </a-radio>
                        <a-radio :value="0">隐藏</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item label="恐惧分值表" name="fear_Score_table">
                    <a-radio-group v-model:value="vrData.data.fear_Score_table"  @change="fearC">
                        <a-radio :value="1">显示 </a-radio>
                        <a-radio :value="0">隐藏</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item label="背景音乐" name="audio_id" >
                    <div class="vr-vide-wrap">
                        <span class="vr-vide-title">音乐状态：</span>
                        <a-radio-group v-model:value="vrData.data.audio_play"  @change="audioplayC">
                            <a-radio :value="1">播放 </a-radio>
                            <a-radio :value="2">暂停 </a-radio>
                        </a-radio-group>
                    </div>
                    <div class="vr-vide-wrap">
                        <span class="vr-vide-title">背景音乐：</span>
                        <a-radio-group v-model:value="vrData.data.audio_id" @change="audioC">
                            <a-radio :value="1">音乐一 </a-radio>
                            <a-radio :value="2">音乐二 </a-radio>
                            <a-radio :value="3">音乐三 </a-radio>
                            <a-radio :value="4">音乐四</a-radio>
                        </a-radio-group>
                    </div>
                </a-form-item>
                
                <a-form-item label="教学视频" name="audio_id" >
                    <div class="vr-vide-wrap">
                        <span class="vr-vide-title">视频模式：</span>
                        <a-radio-group v-model:value="vrData.data.video_position" @change="positionC">
                            <a-radio :value="1">大框模式 </a-radio>
                            <a-radio :value="3">小框模式 </a-radio>
                        </a-radio-group>
                    </div>

                    <div class="vr-vide-wrap">
                        <span class="vr-vide-title">教学视频：</span>
                        <a-radio-group v-model:value="vrData.data.video_id" @change="videorC">
                            <a-radio :value="0">想象 </a-radio>
                            <a-radio :value="1">正念呼吸 </a-radio>
                            <a-radio :value="2">正念行走 </a-radio>
                            <a-radio :value="3">深呼吸技巧 </a-radio>
                            <a-radio :value="4">缩唇呼吸 </a-radio>
                            <a-radio :value="5">腹式呼吸 </a-radio>
                        </a-radio-group>
                    </div>
                    

                    <div class="vr-vide-wrap">
                        <span class="vr-vide-title">视频状态：</span>
                        <a-radio-group v-model:value="vrData.data.video_play" @change="videoplayC">
                            <a-radio :value="1">播放 </a-radio>
                            <a-radio :value="2">隐藏 </a-radio>
                        </a-radio-group>
                    </div>

                
                    
                </a-form-item>
                        <a-form-item label="教学音频" name="audio_id" >
                    <div class="vr-vide-wrap">
                        <span class="vr-vide-title">音频：</span>
                        <a-radio-group v-model:value="vrData.data.audio_video_id" @change="audiovideorC">
                            <a-radio :value="0">暂停一下 </a-radio>
                            <a-radio :value="1">继续走 </a-radio>
                            <a-radio :value="2">数值稳定 </a-radio>
                        </a-radio-group>
                    </div>
                        <div class="vr-vide-wrap">
                        <span class="vr-vide-title">音频状态：</span>
                        <a-radio-group v-model:value="vrData.data.play_audio_video" @change="audiovideoplayC">
                            <a-radio :value="1">播放 </a-radio>
                            <a-radio :value="2">暂停 </a-radio>
                        </a-radio-group>
                    </div>
                        </a-form-item>
                </a-form>
            </div>
            
        </a-drawer>

        <!-- 添加、编辑患者 -->
        <a-modal v-model:visible="visible" :title="title" @ok="handleOk" >
             <a-form
                :model="formData.data"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 16 }"
                autocomplete="off"
               
            >
                <a-form-item label="姓名" name="username" >
                    <a-input placeholder="姓名" v-model:value="formData.data.username" />
                </a-form-item>

                <a-form-item label="手机号" name="telephone" >
                    <a-input placeholder="手机号" type="tel" v-model:value="formData.data.telephone" />
                </a-form-item>

                <a-form-item label="年龄" name="age" >
                    <a-input placeholder="年龄" v-model:value="formData.data.age" />
                </a-form-item>

                <a-form-item label="性别" name="sex" >
                    <a-radio-group v-model:value="formData.data.sex">
                        <a-radio :value="1">男</a-radio>
                        <a-radio :value="2">女</a-radio>
                    </a-radio-group>
                </a-form-item>
            </a-form>
        </a-modal>

         <a-modal v-model:visible="isEdit" title="初始距离" @ok="handleOkEdit" >
             <a-form
                :model="formData.data"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 16 }"
                autocomplete="off"
               
            >
                <a-form-item label="初始距离" name="username" >
                    <a-input placeholder="初始距离" v-model:value="initial_distance" />
                </a-form-item>

            </a-form>
        </a-modal>
    </div>
</template>


<script>
import { nextTick, onBeforeUnmount, onMounted, reactive,ref } from 'vue';
import {ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getCurrentInstance ,createVNode} from 'vue'
import { message,Modal} from 'ant-design-vue';
import * as echarts from 'echarts';
import { notification } from 'ant-design-vue';

import socket from "@/common/websocket.js";

// import SocketService from './../../common/websocket';
// import WebSocket from 'websocket';
const columns = [
    {title: 'ID',dataIndex: 'id',width:90},
    {title: '姓名',dataIndex: 'username'},
    {title: '手机号',dataIndex: 'telephone'},
    {title: '年龄',dataIndex: 'age'},
    {title: '性别',dataIndex: 'sex'},
    {title: 'VR监控',dataIndex: 'VR',width:110},
    {title: '添加时间',dataIndex: 'create_time'},
    {title: '操作',dataIndex: 'operation',width:180}
];
export default {
    setup(){
        const { proxy } = getCurrentInstance()
        let dataList=reactive({arr:[]})
        
        let page=reactive({page:"",pageSize:10,total:20})//分页

        // 修改初始距离
        let isEdit=ref(false)
        let openEditdistance=function(){
            isEdit.value=true
        }
        let handleOkEdit=function(){
            proxy.$http.post('/admin/updateuser',{id:editid.value,initial_distance:initial_distance.value},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    isEdit.value=false
                    message.success(res.data.msg)
                    getTabledata()
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        // 搜索
        let search=ref()
        let toSearch=function(){
            page.page="1"
            getTabledata()
        }
        let colse=function(){
            vrvisible.value=false
            closeConnect()
            connect()
        }
        let colsevrinfo=function(){
            
            vrvisible.value=false
            closeConnect()
            connect()
        }

        // 列表
        let updateVR=function(tel,name,age){
            openVR(tel,name,age)
        }
         let getTabledata=function(){
            proxy.$http.post('/admin/userlist',{page:page.page,pagesize:page.pageSize,search:search.value},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data.data
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()

        const connect = () => {
            socket.connectWebsocket();
        };

        

        const closeConnect = () => {
            if (socket.isConnection()) socket.closeWebSocket();
        };

        onBeforeUnmount(() => {
            closeConnect();
        })

        connect()

        // VR编辑
        let editid=ref()
        let kjnum=ref()
        let hxnum=ref()
        let vrvisible=  ref(false)
        let vrData=reactive({data:{}})
        let vrtel=ref("")
        let vrname=ref()
        let maxxl=ref()
        let vrage=ref()
        let distance=ref()
        let is_select_video=ref()
        let initial_distance=ref()
        let openVR=function(tel,name,age,id){
            editid.value=id
            vrtel.value=tel
            vrname.value=name
            vrage.value=age
            maxxl.value=Math.round(207-(0.7*parseInt(age))) 
            proxy.$http.post('/admin/darelogpage',{telephone:tel},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    vrvisible.value=true
                    vrData.data=res.data.data.data.dare_log_temporary
                    initial_distance.value=res.data.data.data.initial_distance
                    socket.webSocketSend(tel);
                    var option;
                 
                    nextTick(()=>{
                        let xldata=[]
                        let hxdata=[]
                        let xydata=[]
                        let sdata=[]
                        let myChart = echarts.init(document.getElementById('indexCharts'));
                        option={
                            legend: {
                                data: ['SPO2', '心率','呼吸频率']
                            },
                            tooltip: {
                                trigger: 'axis',
    
                                // axisPointer: {
                                //     animation: false
                                // }
                            },
                            xAxis: {
                                splitLine: {
                                    show: false
                                },
                                data: sdata
                            },
                            yAxis:{
                                splitLine: {
                                    show: false
                                }
                            },
                            grid: {top:'17%',left:"6%",right:"6%"},
                            series: [
                                {
                                    name: "SPO2",
                                    type: "line",
                                    smooth: true,
                                    showSymbol: false,
                                    itemStyle:{
                                        normal: {
                                            color:"#409EFC",
                                            lineStyle: {
                                                width:2,
                                                color:"#409EFC"
                                            }
                                        }
                                    },
                                    data: xydata
                                },
                                {
                                    name: "心率",
                                    type: "line",
                                    smooth: true,
                                    showSymbol: false,
                                    itemStyle:{
                                        normal: {
                                            color:"#ec2020",
                                            lineStyle: {
                                                width:2,
                                                color:"#ec2020"
                                            }
                                        }
                                    },
                                    data: xldata
                                },
                                {
                                    name: "呼吸频率",
                                    type: "line",
                                    smooth: true,
                                    showSymbol: false,
                                    itemStyle:{
                                        normal: {
                                            color:"#3ea03f",
                                            lineStyle: {
                                                width:2,
                                                color:"#3ea03f"
                                            }
                                        }
                                    },
                                    data: hxdata
                                }
                            ]
                        };
                        // window.addEventListener("receive",getServerInfo)
                        // const messageData = ref(null);
                        const getServerInfo = (data) => {
                        
                            var info=JSON.parse(data.detail)
                            //    console.log(info);
                                info.content=info.content.replace(/\'/g,"\"")
                               console.log(info.content)
                    
                         
                            info.content=JSON.parse(info.content )
                            if(info.content.is_select_video=='1'){
                                notification['info']({
                                    message: '请医生选择播放视频',
                                });
                            }
                            console.log("info.content.tag="+info.content.tag)
                            if(info.content.tag=="1"){
                                kjnum.value=info.content.mate
                            }else if(info.content.tag=="2"){
                                hxnum.value=info.content.mate
                            }
                            if(info.content.type=="1"){
                                var timer=setInterval(function () {
                                if (xydata.length>5) {
                                    xydata.shift()
                                

                                    xldata.shift()
                        
                                    hxdata.shift()
                                
                                    sdata.shift()
                                
                                }
                                    xydata.push(info.content.spo);
                                    xldata.push(info.content.heart_rate);
                                    hxdata.push(info.content.RR);
                                    sdata.push(info.content.time)
                                    myChart.setOption({
                                    xAxis: {
                                        data: sdata
                                    },
                                    series: [
                                        {
                                            data: xydata
                                        },
                                        {
                                            data: xldata
                                        },
                                        {
                                            data: hxdata
                                        }
                                    ]
                                })
                                clearInterval(timer)
                                }, 1000);
                
                                // console.log(sdata)
                            
                                myChart.setOption(option)
                            }else if(info.content.type=="2"){
                                distance.value=info.content.epicycle_distance_db
                            }
                            // hxdata.shift();
                           
                        };
                         window.addEventListener("receive", getServerInfo);

                    })
                    
                }else{
                    message.error(res.data.msg);
                }
            });
            

            
            


       
        }




  

        let daretypeC=function(e){
            vrData.data.dare_type=e.target.value
            handleOkVR()
        }
        let darestateC=function(e){
            vrData.data.dare_state=e.target.value
            handleOkVR()
        }
        let speedC=function(e){
            vrData.data.speed=e.target.value
            handleOkVR()
        }
        let scoreC=function(e){
            vrData.data.dyspnea_Score_table=e.target.value
            handleOkVR()
        }
        let fearC=function(e){
            vrData.data.fear_Score_table=e.target.value
            handleOkVR()
        }
        let audioplayC=function(e){
            vrData.data.audio_play=e.target.value
            handleOkVR()
        }
        let audioC=function(e){
            vrData.data.audio_id=e.target.value
            handleOkVR()
        }
        let positionC=function(e){
            vrData.data.video_position=e.target.value
            handleOkVR()
        }
        let videorC=function(e){
            vrData.data.video_id=e.target.value
            handleOkVR()
        }
         let videoplayC=function(e){
            vrData.data.video_play=e.target.value
            handleOkVR()
        }
        let audiovideorC=function(e){
            vrData.data.audio_video_id=e.target.value
            handleOkVR()
        }
        let audiovideoplayC=function(e){
            vrData.data.play_audio_video=e.target.value
            handleOkVR()
        }
        let handleOkVR=function(){
             proxy.$http.post('/admin/updatedarelog',{telephone:vrtel.value,dare_type:vrData.data.dare_type,dare_state:vrData.data.dare_state,speed:vrData.data.speed,
                dyspnea_Score_table:vrData.data.dyspnea_Score_table,fear_Score_table:vrData.data.fear_Score_table,audio_id:vrData.data.audio_id,
                video_id:vrData.data.video_id,audio_play:vrData.data.audio_play,video_play:vrData.data.video_play,video_position:vrData.data.video_position,
                play_audio_video:vrData.data.play_audio_video,audio_video_id:vrData.data.audio_video_id
                },{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                    }else{
                        message.error(res.data.msg);
                    }
                });
        }

        // 添加、编辑
        let formData=reactive({data:{username:"",telephone:"",age:"",sex:""}})
        let title=ref("")
        let visible=ref(false)
        let openAdd=function(){
            formData.data.username=""
            formData.data.telephone=""
            formData.data.age=""
            formData.data.sex=""
            title.value="添加患者"
            visible.value=true
        }

        let openEdit=function(id){
            proxy.$http.post('/admin/userpage',{id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    visible.value=true
                    title.value="编辑患者"
                    formData.data=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        let handleOk=function(){
            if(title.value=="添加患者"){
                proxy.$http.post('/admin/adduser',{username:formData.data.username,telephone:formData.data.telephone,age:formData.data.age,
                sex:formData.data.sex},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }else if(title.value=="编辑患者"){
                proxy.$http.post('/admin/updateuser',{id:formData.data.id,username:formData.data.username,telephone:formData.data.telephone,age:formData.data.age,
                sex:formData.data.sex},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }
        }
        // 多选
        let selectedRowKeys=ref()
        let onSelectChange=function(selected) {
            selectedRowKeys.value = selected;
        }

         // 删除
        let delMore=function(){
            var id=selectedRowKeys.value.join(",")
            del(id)
        }
        let del=function(id){
            Modal.confirm({
                title: '你确定要删除吗？',
                icon: createVNode(ExclamationCircleOutlined),
            onOk() {
                proxy.$http.post('/admin/deluser',{ids:id},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            },
            onCancel() {
             console.log('Cancel');
            }
            })
            
        }



        // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
            getTabledata()
        }
        return{
            // websocket,
            distance,
            isEdit,openEditdistance,handleOkEdit,vrage,
            is_select_video,maxxl,
            selectedRowKeys,colse,colsevrinfo,hxnum,kjnum,initial_distance,
            vrvisible,handleOkVR,openVR,vrData,daretypeC,darestateC,speedC,scoreC,fearC,audioplayC,audioC,positionC,videorC,videoplayC,vrname,updateVR,vrtel,audiovideorC,audiovideoplayC,
            toSearch,search,
            visible,formData,openAdd,title,handleOk,openEdit,
            onSelectChange,delMore,del,
            columns,dataList,pageChange,page
        }
    }
}
</script>