<template>
    <div class="main-index" >
        <!-- <a-card title="慢阻肺VR调控平台" :bordered='false'> -->
            <h1 class="main-index-title">慢阻肺VR调控平台</h1>
            <a-row :gutter="32" style="height:92%">
                <a-col :span="17"  class="index-top-content">
                    <a-row :gutter="32">
                        <a-col :span="8" class="left-top-wrap">
                            <h2 class="left-top">
                                <p>医护数量</p>
                                <div>
                                    <span style="color:#3191ff" class="num">{{totalData.data.admin_no}}</span><medicine-box-filled :style="{ color: '#3191ff' ,fontSize:'34px'}"/>
                                </div>
                                
                            </h2>
                            
                        </a-col>
                        <a-col :span="8" class="left-top-wrap">
                            <h2 class="left-top">
                                <p>患者数量</p>
                                <div>
                                    <span style="color:#5eb85e"  class="num">{{totalData.data.user_no}}</span><idcard-filled :style="{ color: '#5eb85e' ,fontSize:'34px'}"/>
                                </div>
                                
                            </h2>
                        </a-col>
                        <a-col :span="8" class="left-top-wrap">
                            <h2 class="left-top">
                                <p>今日答题人数</p>
                                <div>
                                    <span style="color:#ff992e" class="num">{{totalData.data.animation_aq_user_answer_today_no}}</span><snippets-filled :style="{ color: '#ff992e',fontSize:'34px' }"/>
                                </div>
                                
                            </h2>
                        </a-col>
                    </a-row>

                    <h1 class="main-index-title" style="margin-top:26px">答题统计</h1>
                    
                    <a-row :gutter="32" class="left-bottom-conten" >
                        <a-col :span="24" class="left-top-wrap left-bottom-wrap">
                            <div id="indexCharts" style="width:100%;height:100%;background:#fff;box-size:border-box;padding-top:20px"></div>
                        </a-col>
                    </a-row>

       
                       

                </a-col>
                <a-col class="left-top-wrap right-all-content" :span="7">
                    <div class="right-top-wrap">
                       <user-outlined  :style="{ fontSize:'34px' }"/>
                       <p> 欢迎您，{{username}}</p>
                       <h2>今天是：{{currentDate}}</h2>
                    </div>

                    <h1 class="main-index-title" style="margin-top:26px">操作日志</h1>

                    <div style="width:100%;height:100%;background:#fff;box-size:border-box;padding:10px 12px 20px">
                         <a-list item-layout="horizontal" :data-source="logData.arr">
                            <template #renderItem="{ item }">
                            <a-list-item>
                                <a-list-item-meta :description="'操作人:'+ item.username+' 操作时间：'+item.create_time " >
                                <template #title>
                                    {{ item.title }}
                                </template>
                                <template #avatar>
                                    <smile-filled :style="{ color: '#5eb85e' }"/>
                                    <!-- <a-avatar src="https://joeschmoe.io/api/v1/random" /> -->
                                </template>
                                </a-list-item-meta>
                            </a-list-item>
                            </template>
                        </a-list>
                        <a-pagination
                        size="small"
                        :showSizeChanger="showSizeChanger"
                            :total="page.total"
                            @change="pageChange"
                            :show-total="total => `共 ${total} 条数据`"
                        />
                    </div>
                </a-col>
            </a-row>
        <!-- </a-card> -->
    </div>
</template>
<script>
import { ref ,reactive } from '@vue/reactivity'
import * as echarts from 'echarts';
import { getCurrentInstance } from 'vue'
import { message } from 'ant-design-vue';
import moment from "moment";
export default {
    setup(){
        let username=ref(sessionStorage.getItem("username"))

        let searchData=reactive({create_time:""})
        const { proxy } = getCurrentInstance();
        var currentDate=ref(moment(new Date()).format("YYYY-MM-DD"))
        // 折线图
        let getTableData= function(){
            // var date=""
            // console.log(searchData.create_time)
            //  if(searchData.create_time){
            //     date=moment(searchData.create_time+" 00:00:00").unix()+"-"+moment(searchData.create_time+" 23:59:59").unix()
            // }

            proxy.$http.post('/admin/indexnolinechart',{},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    let myChart = echarts.init(document.getElementById('indexCharts'));
                    myChart.setOption({
                        legend: {
                            data: ['答题总数', '正确总数']
                        },
                        xAxis: {
                            data: res.data.data.times
                        },
                        yAxis:{},
                        grid: {top:'17%',left:"6%",right:"6%"},
                        series: [
                            {
                                name: "答题总数",
                                type: "line",
                                smooth: true,
                                itemStyle:{
                                    normal: {
                                        lineStyle: {
                                            width:5,
                                            color:"#409EFC"
                                        }
                                    }
                                },
                                data: res.data.data.animation_aq_user_answer_nos
                            },
                            {
                                name: "正确总数",
                                type: "line",
                                smooth: true,
                                itemStyle:{
                                    normal: {
                                        lineStyle: {
                                            width:5,
                                            color:"#3ea03f"
                                        }
                                    }
                                },
                                data: res.data.data.animation_aq_user_answer_true_nos
                            }
                        ]
                    });
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTableData()

        // 数据
        var totalData=reactive({data:{}})
        var getTotalData=function(){
            proxy.$http.post('/admin/indexnostatistics',{},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    totalData.data=res.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTotalData()
        // 操作日志
        let showSizeChanger=ref(false)
        let page=reactive({page:"1",pageSize:"6",total:""})
        let logData=reactive({arr:[]})
        let getLogdata=function(){
            proxy.$http.post('/admin/adminloglist',{page:page.page,pagesize:page.pageSize,search:searchData.value},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    logData.arr=res.data.data.data
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getLogdata()

        // 分页
        let pageChange=function(current){
            page.pageSize="6"
            page.page=current
            getLogdata()
        }
        return{
            username,currentDate,totalData,
            page,logData,showSizeChanger,pageChange
        }
    }
}
</script>