import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import login from '../views/login.vue'
import register from '../views/register.vue'
import index from '../views/index/index'

import animationlist from '../views/animation/animationlist.vue'
import answerlist from '../views/animation/answerlist'

import examdata from '../views/statistics/examdata.vue'
import vrdata from '../views/statistics/vrdata'
import vrlog from '../views/statistics/vrlog'

import nodelist from '../views/authority/nodelist.vue'
import rolelist from '../views/authority/rolelist'
import doctorlist from '../views/doctor/doctorlist.vue'
import patientlist from '../views/patient/patientlist'

import person from '../views/system/person'
import voicelist from '../views/system/voicelist'
import system from '../views/system/system'

import devicelist from '../views/device/devicelist.vue'
import applist from '../views/device/applist'


const routes = [
  {path: '/',name: 'home',component: HomeView, redirect: {path: '/index' },
  children:[
    {path:'index',name:'index',component:index,meta:{title:"首页"}},
    {path:'animationlist',name:'animationlist',component:animationlist,meta:{title:"动画视频"}},
    {path:'answerlist',name:'answerlist',component:answerlist,meta:{title:"知识问答"}},

    {path:'nodelist',name:'nodelist',component:nodelist,meta:{title:"节点管理"}},
    {path:'rolelist',name:'rolelist',component:rolelist,meta:{title:"角色管理"}},
    {path:'examdata',name:'examdata',component:examdata,meta:{title:"知识讲堂"}},
    {path:'vrdata',name:'vrdata',component:vrdata,meta:{title:"VR训练"}},
    {path:'vrlog',name:'vrlog',component:vrlog,meta:{title:"VR游戏记录"}},
    {path:'doctorlist',name:'doctorlist',component:doctorlist,meta:{title:"医护列表"}},
    {path:'patientlist',name:'patientlist',component:patientlist,meta:{title:"患者列表"}},
    {path:'devicelist',name:'devicelist',component:devicelist,meta:{title:"设备列表"}},
    {path:'applist',name:'applist',component:applist,meta:{title:"App版本"}},


    {path:'system',name:'system',component:system,meta:{title:"系统配置"}},
    {path:'voicelist',name:'voicelist',component:voicelist,meta:{title:"语音管理"}},
    {path:'person',name:'person',component:person,meta:{title:"个人信息"}}
  ]},
  {path: '/login',name: 'login',component: login,meta:{title:"登录"}},
  {path: '/register',name: 'register',component: register,meta:{title:"注册"}}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from , next) => {
  const title = (to.meta.title ? to.meta.title : '') + '-慢阻肺VR-虚拟调控平台'
  document.title = title
  // 判断是否含有登录标识
  const isLogin = sessionStorage.getItem('token')
  console.log("isLogin="+isLogin)
  // 想要跳转到 person页面 但是没有标识 就next 去登录
    if(!isLogin){
      if(to.name=="login"||to.name=="register"){
        next()
      }else{
        router.push('login')
      }
    }else{
      next()
    }
})

export default router
