<template>
    <div>
        <a-card title="设备管理" :bordered='false'>
            <div class="table-wrap">
                <div class="btn-wrap">
                    <div class="btn-list">
                        <a-input-group compact>
                            <a-select ref="select" style="width:160px" allowClear placeholder="设备类型" v-model:value="searchlist.type">
                                <a-select-option :value="1" >血氧仪</a-select-option>
                                <a-select-option :value="2" >计步器</a-select-option>
                            </a-select>
                            <a-button type="primary" @click="toSearch">搜索</a-button>
                        </a-input-group>
                        <a-button type="primary" @click="openAdd"><template #icon><PlusOutlined /></template>添加设备</a-button>
                        <a-button type="danger" @click="delMore"><template #icon><DeleteOutlined /></template>删除</a-button>
                    </div>
                    <div>
                        <a-input-group compact>
                            <a-input v-model:value="search" placeholder="请输入设备号"/>
                            <a-button type="primary" @click="toSearch">搜索</a-button>
                        </a-input-group>
                    </div>
                    
                </div>
                <a-table row-key="id" :pagination="false" :columns="columns" :data-source="dataList.arr"
                    :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                >
                    <template #bodyCell="{ column,record}">
                        <template v-if="column.dataIndex === 'type'">
                            <span v-if="record.type==1">血氧仪</span>
                            <span v-if="record.type==2">计步器</span>
                        </template>
                        <template v-if="column.dataIndex === 'operation'">
                            <a-button type="link" @click="openEdit(record.id)">编辑</a-button>
                            <a-button type="link" @click="del(record.id)">删除</a-button>
                        </template>
                    </template>
                </a-table>

                <a-pagination
                    v-model:pageSize="page.pageSize"
                    show-size-changer
                    :total="page.total"
                    @change="pageChange"
                    :show-total="total => `共 ${total} 条数据`"
                />
            </div>

            <!-- 添加、编辑设备 -->
            <a-modal v-model:visible="visible" :title="title" @ok="handleOk" >
                <a-form
                    :model="formData.data"
                    :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 16 }"
                    autocomplete="off"
                >
                    <a-form-item label="名称" name="title" >
                        <a-input placeholder="名称" v-model:value="formData.data.title" />
                    </a-form-item>
                    
                    <a-form-item label="设备类型" name="sort" >
                        <a-radio-group v-model:value="formData.data.type">
                            <a-radio :value="1">血氧仪</a-radio>
                            <a-radio :value="2">计步器</a-radio>
                        </a-radio-group>
                    </a-form-item>

                    <a-form-item label="设备ID" name="title" >
                        <a-input placeholder="设备ID（蓝牙地址）" v-model:value="formData.data.peripheralUUID" />
                    </a-form-item>

                </a-form>
            </a-modal>
        </a-card>
    </div>
</template>

<script>
import { reactive,ref } from 'vue';
import {ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getCurrentInstance ,createVNode,watch} from 'vue'
import { message,Modal} from 'ant-design-vue';
const columns = [
    {title: 'ID',dataIndex: 'id',width:90},
    {title: '名称',dataIndex: 'title'},
    {title: '设备类型',dataIndex: 'type'},
    {title: '设备ID',dataIndex: 'peripheralUUID'},
    {title: '操作',dataIndex: 'operation',width:180}
];

export default {
    setup(){
        const { proxy } = getCurrentInstance()
        let dataList=reactive({arr:[]})
        let headers=reactive({'token':sessionStorage.getItem("token")})
        let page=reactive({pageSize:"10",total:"20",page:"1"})//分页
        let searchlist=reactive({type:""})
         // 上传
        var fileList=ref()
        let beforeUpload=function(file){
            formData.data.file_src=file
        }
        var loading=ref(false)
        let handleChange=function(info){
            // console.log(info)
            if (info.file.status === 'uploading') {
                loading.value = true;
                return;
            }else if(info.file.status === 'done'){
                loading.value = false;
                console.log(info)
                // fileList.value=info
                formData.data.file_src=info.file.response.data.file_src_oss
            }
        }

         // 多选
        let selectedRowKeys=ref()
        let onSelectChange=function(selected) {
            selectedRowKeys.value = selected;
        }

          // 删除
        let delMore=function(){
            var id=selectedRowKeys.value.join(",")
            del(id)
        }
        let del=function(id){
            Modal.confirm({
                title: '你确定要删除吗？',
                icon: createVNode(ExclamationCircleOutlined),
            onOk() {
                proxy.$http.post('/admin/deldevice',{ids:id},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            },
            onCancel() {
             console.log('Cancel');
            }
            })
            
        }

        // 搜索
        let search=ref()
        let toSearch=function(){
            page.page="1"
            getTabledata()
        }
          // 列表
        let getTabledata=function(){
            proxy.$http.post('/admin/devicelist',{page:page.page,pagesize:page.pageSize,search:search.value,type:searchlist.type},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data.data
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()

        // 添加、编辑
        let formData=reactive({data:{title:"",type:1,peripheralUUID:""}})
        let title=ref("")
        let visible=ref(false)
        let openAdd=function(){
            formData.data.title=""
            formData.data.type=1
            formData.data.peripheralUUID=""
            title.value="添加设备"
            visible.value=true
        }
         let openEdit=function(id){
            proxy.$http.post('/admin/devicepage',{id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    visible.value=true
                    title.value="编辑设备"
                    formData.data=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        let handleOk=function(){
        
            if(title.value=="添加设备"){
                proxy.$http.post('/admin/adddevice',{title: formData.data.title,type: formData.data.type,peripheralUUID:formData.data.peripheralUUID},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }else if(title.value=="编辑设备"){
                proxy.$http.post('/admin/updatedevice',{id:formData.data.id,title:formData.data.title,type:formData.data.type,peripheralUUID:formData.data.peripheralUUID},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }
            
        }


         // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
            getTabledata()
        }
        return{
            handleChange,headers,
            visible,formData,openAdd,title,handleOk,openEdit,
            getTabledata,toSearch,searchlist,
            onSelectChange,delMore,del,
            columns,dataList,pageChange,page
        }
    }
}
</script>