<template>
    <div>
        <div class="btn-wrap">
                <div class="btn-list">
                    <a-button type="primary" @click="openAdd"><template #icon><plus-outlined /></template>添加语音</a-button>
                    <a-button type="danger" @click="delMore"><template #icon><delete-outlined /></template>删除</a-button>
                </div>
                <div>
                    <a-input-group compact>
                        <a-input v-model:value="search"  placeholder="请输入语音名称"/>
                        <a-button type="primary" @click="toSearch">搜索</a-button>
                    </a-input-group>
                </div>
            </div>

            <a-table row-语音="id" :pagination="false" :columns="columns" :data-source="dataList.arr" :row-selection="{ selectedRowkeys: selectedRowkeys, onChange: onSelectChange }">
                <template #bodyCell="{ column,record}">
                    <template v-if="column.dataIndex === 'tag'">
                        <span v-if="record.tag==1">恐惧</span>
                        <span v-if="record.tag==2">呼吸困难</span>
                    </template>

                    <template v-if="column.dataIndex === 'operation'">
                        <a-button type="link" @click="openEdit(record.id)">编辑</a-button>
                        <a-button type="link" @click="del(record.id)">删除</a-button>
                    </template>
                </template>
            </a-table>

            <a-pagination
                v-model:pageSize="page.pageSize"
                show-size-changer
                :total="page.total"
                @change="pageChange"
                :show-total="total => `共 ${total} 条数据`"
            />

        <!-- 添加、编辑账户 -->
        <a-modal v-model:visible="visible" :title="title" @ok="handleOk" >
            <!-- <div style="width:390px"> -->
                <a-form
                    :model="formData.data"
                    :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 20 }"
                    autocomplete="off"
                >
                    <a-form-item label="语音标题" name="title" >
                        <a-input placeholder="语音标题" v-model:value="formData.data.title" />
                    </a-form-item>
                    <a-form-item label="语音内容" name="remarks" >
                        <a-textarea v-model:value="formData.data.content" placeholder="变量用{var}表示。例：呼吸困难{var}分" :rows="4" />
                    </a-form-item>
                    <a-form-item label="类型" name="remarks" >
                       <a-radio-group v-model:value="formData.data.tag">
                            <a-radio :value="1">恐惧</a-radio>
                            <a-radio :value="2">呼吸困难</a-radio>
                        </a-radio-group>
                    </a-form-item>

                    
                </a-form>
            <!-- </div> -->
             
        </a-modal>
    </div>
</template>

<script>
import { reactive,ref } from 'vue';
import {ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getCurrentInstance ,createVNode} from 'vue'
import { message,Modal} from 'ant-design-vue';
const columns = [
    {title: 'ID',dataIndex: 'id',width:90},
    {title: '语音名称',dataIndex: 'title'},
   
    {title: '内容',dataIndex: 'content'},
    {title: '类型',dataIndex: 'tag'},
    {title: '添加时间',dataIndex: 'create_time'},
    {title: '操作',dataIndex: 'operation',width:180}
];
export default {
    setup(){
        const { proxy } = getCurrentInstance()
        let dataList=reactive({arr:[]})
        
        let page=reactive({page:"",pageSize:"10",total:"20"})//分页

        // 搜索
        let search=ref()
        let toSearch=function(){
            page.page="1"
            getTabledata()
        }



        // 列表
         let getTabledata=function(){
            proxy.$http.post('/admin/scriptlist',{page:page.page,pagesize:page.pageSize,title:search.value},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data.data
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()

        // 添加、编辑
        let formData=reactive({data:{title:"",content:"",tag:""}})
        let title=ref("")
        let visible=ref(false)
        let openAdd=function(){
            formData.data.title=""
            formData.data.content=""
            formData.data.tag=""
            title.value="添加语音"
            visible.value=true
        }

        let openEdit=function(id){
            proxy.$http.post('/admin/scriptpage',{id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    visible.value=true
                    title.value="编辑语音"
                    formData.data=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        let handleOk=function(){

            if(title.value=="添加语音"){
               
                proxy.$http.post('/admin/addscript',{title:formData.data.title,content:formData.data.content,tag:formData.data.tag
                },{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
                
                
            }else if(title.value=="编辑语音"){
                proxy.$http.post('/admin/updatescript',{id:formData.data.id,title:formData.data.title,content:formData.data.content,tag:formData.data.tag
                },{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }
        }
        // 多选
        let selectedRowkeys=ref()
        let onSelectChange=function(selected) {
            selectedRowkeys.value = selected;
        }

         // 删除
        let delMore=function(){
            var id=selectedRowkeys.value.join(",")
            del(id)
        }
        let del=function(id){
            Modal.confirm({
                title: '你确定要删除吗？',
                icon: createVNode(ExclamationCircleOutlined),
            onOk() {
                proxy.$http.post('/admin/delscript',{ids:id},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            },
            onCancel() {
             console.log('Cancel');
            }
            })
            
        }



        // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
            getTabledata()
        }
        return{
            toSearch,search,
            visible,formData,openAdd,title,handleOk,openEdit,
            onSelectChange,delMore,del,selectedRowkeys,
            columns,dataList,pageChange,page
        }
    }
}
</script>