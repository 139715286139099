<template>
    <div>
        <a-card title="动画视频" :bordered='false'>
            <div class="btn-wrap">
                <div class="search-list">
                    <a-button type="primary" @click="openAdd"><template #icon><plus-outlined /></template>添加动画</a-button>
                    <a-button type="danger" @click="delMore"><template #icon><delete-outlined /></template>删除</a-button>
                </div>
                <div>
                    <a-input-group compact>
                        <a-input v-model:value="search"  placeholder="请输入动画名称"/>
                        <a-button type="primary" @click="toSearch">搜索</a-button>
                    </a-input-group>
                </div>
            </div>

            <a-table :pagination="false" :columns="columns" :data-source="dataList.arr" bordered :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
                <template #bodyCell="{ column,record}">
                    <template v-if="column.dataIndex === 'info'">
                        <p class="table-text">标题：{{record.title}}</p>
                        <p class="table-text">
                            <span>发布人：{{record.username_admin_user}}</span>
                            <span>创建时间：{{record.create_time}}</span>
                        </p>
                    </template>
                    <template v-if="column.dataIndex === 'state'">
                        <!-- {{record.state}} -->
                        <a-switch @change="checked => changeStatus(checked, record.id)" v-model:checked="record.state" :checkedValue="1" :unCheckedValue="0" checked-children="发布" un-checked-children="审核" />
                    </template>
                    <template v-if="column.dataIndex === 'file_src'">
                        <video width="128" v-if="record.file_src" :src="record.file_src"></video>
                    </template>
                    <template v-if="column.dataIndex === 'operation'">
                        <a-button type="link" @click="openEdit(record.id)">编辑</a-button>
                        <a-button type="link" @click="del(record.id)">删除</a-button>
                    </template>
                </template>
            </a-table>
            <!-- {{page.pageSize}} -->
            <a-pagination
                v-model:pageSize="page.pageSize"
                show-size-changer
                :total="page.total"
                @change="pageChange"
                :show-total="total => `共 ${total} 条数据`"
            />
        </a-card>

        <!-- 添加、编辑动画 -->
        <a-modal v-model:visible="visible" :title="title" @ok="handleOk" :confirmLoading="loading">
             <a-form
                :model="formData.data"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 16 }"
                autocomplete="off"
            >
                <a-form-item
                label="动画标题"
                name="username"
                >
                    <a-input placeholder="动画标题" v-model:value="formData.data.title" />
                </a-form-item>
                <a-form-item
                label="视频上传"
                name="username"
                >
                    <a-upload
                        v-model:file-list="fileList"
                        name="file"
                        list-type="picture-card"
                        class="avatar-uploader"
                        :headers="headers"
                        :show-upload-list="false"
                        action="https://mzf.13524.net/admin/addanimationfile"
                        @preview="handlePreview"
                        @change="handleChangeAudio"
                    >
                    <!-- {{formData.data.file}} -->
                        <!-- <img v-if="item.content" :src="item.content" alt="avatar" /> -->
                        <video  v-if="formData.data.file_src" :src="formData.data.file_src" width="128" controls="controls" ref="video">
                            您的浏览器不支持播放该视频！
                        </video>
                        <div v-else>
                            <loading-outlined v-if="loading"></loading-outlined>
                            <plus-outlined v-else></plus-outlined>
                            <div class="ant-upload-text">视频上传</div>
                        </div>

                        <a-modal v-model:visible="videoPreviewVisible" :footer="null"  @cancel="videoPreviewVisible=false" >
                            <video :src="videosrc" width="100%" height="600px;"  controls="controls" ref="video">
                                您的浏览器不支持播放该视频！
                            </video>
                        </a-modal>
                    </a-upload>
                </a-form-item>
                <a-form-item
                label="是否发布"
                name="state"
                >
                    <a-switch v-model:checked="formData.data.state" :checkedValue="1" :unCheckedValue="0" checked-children="发布" un-checked-children="审核" />
                </a-form-item>


            </a-form>
        </a-modal>
    </div>
</template>


<script>
import { reactive,ref } from 'vue';
import {ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getCurrentInstance ,createVNode,watch} from 'vue'
import { message,Modal} from 'ant-design-vue';
const columns = [
    {title: 'ID',dataIndex: 'id',width:90},
    {title: '动画信息',dataIndex: 'info'},
    {title: '动画视频',dataIndex: 'file_src'},
    {title: '发布',dataIndex: 'state'},
    {title: '操作',dataIndex: 'operation',width:180}
];
export default {
    setup(){
        const { proxy } = getCurrentInstance()
        let dataList=reactive({arr:[{id:"1",title:"动画视频讲堂一",ishow:"1"}]})
        let headers=reactive({'token':sessionStorage.getItem("token")})
        const progress= {
        strokeColor: {
            '0%': '#108ee9',
            '100%': '#87d068',
        }}
        
        // 上传
        var fileList=ref()
        let beforeUpload=function(file){
            formData.data.file_src=file
        }
        var loading=ref(false)
        let handleChangeAudio=function(info){
            // console.log(info)
            if (info.file.status === 'uploading') {
                loading.value = true;
                return;
            }else if(info.file.status === 'done'){
                loading.value = false;
                console.log(info)
                // fileList.value=info
                formData.data.file_src=info.file.response.data.file_src_oss
            }
        }
        

        // 视频预览
        let videoPreviewVisible=ref(false)
        let currentInstance = getCurrentInstance()
        watch(()=>videoPreviewVisible,(val)=>{
            if(val === false){
                let video =  currentInstance.proxy.$refs.video;
                video.pause()
            }
        })
        let videosrc=ref()
        let handlePreview=function(file){
            videoPreviewVisible.value=true

            nextTick(()=>{
                videosrc.value = file.url
            })
            return false;
        }

        
        let page=reactive({pageSize:"10",total:"20",page:"1"})//分页
     // 搜索
        let search=ref()
        let toSearch=function(){
            page.page="1"
            getTabledata()
        }
          // 列表
         let getTabledata=function(){
            proxy.$http.post('/admin/animationlist',{page:page.page,pagesize:page.pageSize,search:search.value},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data.data
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()

        // 添加、编辑
        let formData=reactive({data:{title:"",state:1,file_src:""}})
        let title=ref("")
        let visible=ref(false)
        let openAdd=function(){
            formData.data.title=""
            formData.data.state=1
            formData.data.file_src=""
            title.value="添加动画"
            visible.value=true
        }
         let openEdit=function(id){
            proxy.$http.post('/admin/animationpage',{id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    visible.value=true
                    title.value="编辑动画"
                    formData.data=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        let handleOk=function(){
            if(title.value=="添加动画"){
                proxy.$http.post('/admin/addanimation',{title: formData.data.title,file_src: formData.data.file_src,state: formData.data.state},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }else if(title.value=="编辑动画"){
                proxy.$http.post('/admin/updateanimation',{id:formData.data.id,title:formData.data.title,state:formData.data.state,
                file_src:formData.data.file_src},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }
            
        }

        let changeStatus=function(checked,id){

            proxy.$http.post('/admin/updateanimation',{id:id,pid:formData.data.pid,state:checked},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
        }
        // 多选
        let selectedRowKeys=ref()
        let onSelectChange=function(selected) {
            selectedRowKeys.value = selected;
        }

          // 删除
        let delMore=function(){
            var id=selectedRowKeys.value.join(",")
            del(id)
        }
        let del=function(id){
            Modal.confirm({
                title: '你确定要删除吗？',
                icon: createVNode(ExclamationCircleOutlined),
            onOk() {
                proxy.$http.post('/admin/delanimation',{ids:id},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            },
            onCancel() {
             console.log('Cancel');
            }
            })
            
        }



        // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
            getTabledata()
        }
        return{
            progress,beforeUpload,fileList,videoPreviewVisible,videosrc,handlePreview,handleChangeAudio,loading,headers,
            toSearch,search,
            visible,formData,openAdd,title,handleOk,openEdit,changeStatus,
            onSelectChange,delMore,del,
            columns,dataList,pageChange,page
        }
    }
}
</script>