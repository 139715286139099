<template>
    <div>
        <a-card title="知识讲堂" :bordered='false'>
            <div class="btn-wrap">
                <div class="search-list" style="display:flex;align-items: center;">
                    <a-input-group compact>
                        <a-input v-model:value="search"  placeholder="请输入患者姓名"/>
                        <a-button type="primary" @click="toSearch">搜索</a-button>
                    </a-input-group>
                    <p style="width: 180px;  margin-left: 20px;font-size:15px;white-space: nowrap;">当前金币数：{{rewardall}}</p>
                    <!-- <a-button type="primary" @click="openAdd"><template #icon><plus-outlined /></template>添加患者</a-button>
                    <a-button type="danger" @click="delMore"><template #icon><delete-outlined /></template>删除</a-button> -->
                </div>
                <!-- <div>
                    <a-input-group compact>
                        <a-input v-model:value="search"  placeholder="请输入患者姓名"/>
                        <a-button type="primary" @click="toSearch">搜索</a-button>
                    </a-input-group>
                </div> -->
            </div>

            <a-table :pagination="false" :columns="columns" :data-source="dataList.arr" bordered >
                <template #bodyCell="{ column,record}">
                    <template v-if="column.dataIndex === 'state'">
                        <span v-if="record.state==1" ><check-circle-filled :style="{ color: '#67c23a' }" /></span>
                        <span v-else ><close-circle-filled :style="{ color: '#e32c19' }" /></span>
                    </template>
                    <!-- <template v-if="column.dataIndex === 'operation'">
                        <a-button type="link" @click="openEdit(record.id)">编辑</a-button>
                        <a-button type="link" @click="del(record.id)">删除</a-button>
                    </template> -->
                </template>
            </a-table>

            <a-pagination
                v-model:pageSize="page.pageSize"
                show-size-changer
                :total="page.total"
                @change="pageChange"
                :show-total="total => `共 ${total} 条数据`"
            />
        </a-card>

    </div>
</template>


<script>
import { reactive,ref } from 'vue';

import { getCurrentInstance } from 'vue'
import { message} from 'ant-design-vue';
const columns = [
    {title: 'ID',dataIndex: 'id',width:90},
    {title: '患者姓名',dataIndex: 'username'},
    {title: '题目',dataIndex: 'title'},
    {title: '题目答案',dataIndex: 'animation_aq_answer'},
    {title: '患者选项',dataIndex: 'answer'},
    {title: '题目对错',dataIndex: 'state'},
    {title: '答题时间',dataIndex: 'create_time'},
];
export default {
    setup(){
        const { proxy } = getCurrentInstance()
        let dataList=reactive({arr:[]})
        
        let page=reactive({page:"1",pageSize:"10",total:""})//分页

        // 搜索
        let search=ref()
        let toSearch=function(){
            page.page="1"
            getTabledata()
        }

        // 列表
        let rewardall=ref()
         let getTabledata=function(){
            proxy.$http.post('/admin/animationaquseranswerlist',{page:page.page,pagesize:page.pageSize,username:search.value},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data.data
                    page.total=res.data.data.total
                    rewardall.value=res.data.data.rewardall
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()

    

        // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
            getTabledata()
        }
        return{
            rewardall,
            toSearch,search,
            columns,dataList,pageChange,page
        }
    }
}
</script>