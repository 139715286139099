<template>
    <div>
        <a-card title="医护列表" :bordered='false'>
            <div class="btn-wrap">
                <div class="search-list">
                    <a-button type="primary" @click="openAdd"><template #icon><plus-outlined /></template>添加医护</a-button>
                    <a-button type="danger" @click="delMore"><template #icon><delete-outlined /></template>删除</a-button>
                </div>
                <div>
                    <a-input-group compact>
                        <a-input v-model:value="search"  placeholder="请输入医护姓名"/>
                        <a-button type="primary" @click="toSearch">搜索</a-button>
                    </a-input-group>
                </div>
            </div>

            <a-table :pagination="false" :columns="columns" :data-source="dataList.arr" bordered :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }">
                <template #bodyCell="{ column,record}">
                    <template v-if="column.dataIndex === 'education'">
                        <span v-if="record.education==1">大专</span>
                        <span v-if="record.education==2">本科</span>
                        <span v-if="record.education==3">研究生</span>
                        <span v-if="record.education==4">博士</span>
                    </template>
                    <template v-if="column.dataIndex === 'operation'">
                        <a-button type="link" @click="openEdit(record.id)">编辑</a-button>
                        <a-button type="link" @click="del(record.id)">删除</a-button>
                    </template>
                </template>
            </a-table>

            <a-pagination
                v-model:pageSize="page.pageSize"
                show-size-changer
                :total="page.total"
                @change="pageChange"
                :show-total="total => `共 ${total} 条数据`"
            />
        </a-card>

        <!-- 添加、编辑医护 -->
        <a-modal v-model:visible="visible" :title="title" @ok="handleOk" >
             <a-form
                :model="formData.data"
                :label-col="{ span: 4 }"
                :wrapper-col="{ span: 16 }"
                autocomplete="off"
            >
                <a-form-item label="姓名" name="username" >
                    <a-input placeholder="姓名" v-model:value="formData.data.username" />
                </a-form-item>

                <a-form-item label="手机号" name="telephone" >
                    <a-input placeholder="手机号" type="tel" v-model:value="formData.data.telephone" />
                </a-form-item>

                <a-form-item label="学历" name="education" >
                    <a-radio-group v-model:value="formData.data.education">
                        <a-radio :value="1">专科</a-radio>
                        <a-radio :value="2">本科</a-radio>
                        <a-radio :value="3">研究生</a-radio>
                        <a-radio :value="4">博士</a-radio>
                    </a-radio-group>
                </a-form-item>

                <a-form-item label="专业" name="major" >
                    <a-input placeholder="专业" v-model:value="formData.data.major" />
                </a-form-item>
                <a-form-item label="角色权限" name="admin_role_id" >
                    <!-- {{roleList.arr}} -->
                    <a-select ref="select" v-model:value="formData.data.admin_role_id"   >
                        <a-select-option v-for="item in roleList.arr" :value="item.id" :key="item.id">{{item.title}}</a-select-option>
                    </a-select>
                </a-form-item>
                <a-form-item label="密码" name="password" >
                    <a-input-password placeholder="密码" v-model:value="formData.data.password" />
                </a-form-item>
                <a-form-item label="确认密码" name="repassword" >
                    <a-input-password placeholder="确认密码" v-model:value="formData.data.repassword" />
                </a-form-item>



            </a-form>
        </a-modal>
    </div>
</template>


<script>
import { reactive,ref } from 'vue';
import {ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getCurrentInstance ,createVNode} from 'vue'
import { message,Modal} from 'ant-design-vue';
const columns = [
    {title: 'ID',dataIndex: 'id',width:90},
    {title: '姓名',dataIndex: 'username'},
    {title: '手机号',dataIndex: 'telephone'},
    {title: '学历',dataIndex: 'education'},
    {title: '专业',dataIndex: 'major'},
    {title: '角色',dataIndex: 'title_admin_role'},
    {title: '添加时间',dataIndex: 'create_time'},
    {title: '操作',dataIndex: 'operation',width:180}
];
export default {
    setup(){
        const { proxy } = getCurrentInstance()
        let dataList=reactive({arr:[{id:"1",title:"医护视频讲堂一",ishow:"1"}]})
        let roleList=reactive({arr:[]})
        let page=reactive({pageSize:"10",total:"20",page:"1"})//分页
        // 角色
         let getRole=function(){
             proxy.$http.post('/admin/rolelist',{pagesize:100},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    roleList.arr=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getRole()

         // 搜索
        let search=ref()
        let toSearch=function(){
            page.page="1"
            getTabledata()
        }

         // 列表
         let getTabledata=function(){
            proxy.$http.post('/admin/adminuserlist',{page:page.page,pagesize:page.pageSize,search:search.value},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data.data
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()

        // 添加、编辑
        let formData=reactive({data:{username:"",telephone:"",education:"",major:"",password:"",repassword:"",admin_role_id:""}})
        let title=ref("")
        let visible=ref(false)
        let openAdd=function(){
            formData.data.username=""
            formData.data.telephone=""
            formData.data.education=""
            formData.data.major=""
            formData.data.password=""
            formData.data.repassword=""
            formData.data.admin_role_id=""
            title.value="添加医护"
            visible.value=true
        }

        let openEdit=function(id){
            proxy.$http.post('/admin/adminuserpage',{id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    visible.value=true
                    title.value="编辑医护"
                    formData.data=res.data.data.data
                    formData.data.password=""
                    formData.data.repassword=""
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        let handleOk=function(){
            if(formData.data.password!=formData.data.repassword){
                message.error("两次密码不一致！");
            }else{
                if(title.value=="添加医护"){
                    proxy.$http.post('/admin/addadminuser',{username:formData.data.username,telephone:formData.data.telephone,
                    education:formData.data.education,major:formData.data.major,password:formData.data.password,admin_role_id:formData.data.admin_role_id},{
                        headers:{
                            'token':sessionStorage.getItem("token")
                        }
                    }).then((res) => {
                        if(res.data.status==200){
                            visible.value=false
                            message.success(res.data.msg)
                            getTabledata()
                        }else{
                            message.error(res.data.msg);
                        }
                    });
                }else if(title.value=="编辑医护"){
                    proxy.$http.post('/admin/updateadminuser',{id:formData.data.id,username:formData.data.username,telephone:formData.data.telephone,
                    education:formData.data.education,major:formData.data.major,password:formData.data.password,admin_role_id:formData.data.admin_role_id},{
                        headers:{
                            'token':sessionStorage.getItem("token")
                        }
                    }).then((res) => {
                        if(res.data.status==200){
                            visible.value=false
                            message.success(res.data.msg)
                            getTabledata()
                        }else{
                            message.error(res.data.msg);
                        }
                    });
                }
            }
            
        }
        // 多选
        let selectedRowKeys=ref()
        let onSelectChange=function(selected) {
            selectedRowKeys.value = selected;
        }

          // 删除
        let delMore=function(){
            var id=selectedRowKeys.value.join(",")
            del(id)
        }
        let del=function(id){
            Modal.confirm({
                title: '你确定要删除吗？',
                icon: createVNode(ExclamationCircleOutlined),
            onOk() {
                proxy.$http.post('/admin/deladminuser',{ids:id},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            },
            onCancel() {
             console.log('Cancel');
            }
            })
            
        }



        // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
            getTabledata()
        }
        return{
            toSearch,search,
            visible,formData,openAdd,title,openEdit,handleOk,roleList,
            onSelectChange,delMore,del,
            columns,dataList,pageChange,page
        }
    }
}
</script>