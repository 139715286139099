<template>
    <div>
        <a-card title="App版本管理" :bordered='false'>
            <div class="table-wrap">
                <div class="btn-wrap">
                    <div class="btn-list">
                        <a-input-group compact>
                            <a-select ref="select" style="width:160px" allowClear placeholder="App类型" v-model:value="searchlist.type">
                                <a-select-option :value="1" >知识讲堂</a-select-option>
                                <a-select-option :value="2" >健康监测</a-select-option>
                            </a-select>
                            <a-button type="primary" @click="toSearch">搜索</a-button>
                        </a-input-group>
                        <a-button type="primary" @click="openAdd"><template #icon><PlusOutlined /></template>添加版本</a-button>
                        <a-button type="danger" @click="delMore"><template #icon><DeleteOutlined /></template>删除</a-button>
                    </div>
                    <div>
                        <a-input-group compact>
                            <a-input v-model:value="search" placeholder="请输入版本号"/>
                            <a-button type="primary" @click="toSearch">搜索</a-button>
                        </a-input-group>
                    </div>
                    
                </div>
                <a-table row-key="id" :pagination="false" :columns="columns" :data-source="dataList.arr"
                    :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                >
                    <template #bodyCell="{ column,record}">
                        <template v-if="column.dataIndex === 'type'">
                            <span v-if="record.type==1">知识讲堂</span>
                            <span v-if="record.type==2">健康监测</span>
                        </template>
                        <template v-if="column.dataIndex === 'operation'">
                            <a-button type="link" @click="openEdit(record.id)">编辑</a-button>
                            <a-button type="link" @click="del(record.id)">删除</a-button>
                        </template>
                    </template>
                </a-table>

                <a-pagination
                    v-model:pageSize="page.pageSize"
                    show-size-changer
                    :total="page.total"
                    @change="pageChange"
                    :show-total="total => `共 ${total} 条数据`"
                />
            </div>

            <!-- 添加、编辑版本 -->
            <a-modal v-model:visible="visible" :title="title" @ok="handleOk" >
                <a-form
                    :model="formData.data"
                    :label-col="{ span: 4 }"
                    :wrapper-col="{ span: 16 }"
                    autocomplete="off"
                >
                    <a-form-item label="名称" name="title" >
                        <a-input placeholder="名称" v-model:value="formData.data.title" />
                    </a-form-item>
                    
                    <a-form-item label="App类型" name="sort" >
                        <a-radio-group v-model:value="formData.data.type">
                            <a-radio :value="1">知识讲堂</a-radio>
                            <a-radio :value="2">健康监测</a-radio>
                        </a-radio-group>
                    </a-form-item>

                    <a-form-item label="版本号" name="title" >
                        <a-input placeholder="版本号，例：1.0.0" v-model:value="formData.data.app_version" />
                    </a-form-item>

                    <a-form-item label="版本上传" name="title" >
                        <a-upload
                            action="https://mzf.13524.net/admin/addappfile"
                            :multiple="false"
                            :headers="headers"
                            :file-list="fileList"
                            @change="handleChange"
                        >
                            <a-button>
                                <upload-outlined></upload-outlined>
                                上传文件
                            </a-button>
                        </a-upload>
                    </a-form-item>
                </a-form>
            </a-modal>
        </a-card>
    </div>
</template>

<script>
import { reactive,ref } from 'vue';
import {ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getCurrentInstance ,createVNode,watch} from 'vue'
import { message,Modal} from 'ant-design-vue';
const columns = [
    {title: 'ID',dataIndex: 'id',width:90},
    {title: '名称',dataIndex: 'title'},
    {title: 'App类型',dataIndex: 'type'},
    {title: '版本号',dataIndex: 'app_version'},
    {title: 'App版本',dataIndex: 'file_src'},
    {title: '操作',dataIndex: 'operation',width:180}
];

export default {
    setup(){
        const { proxy } = getCurrentInstance()
        let dataList=reactive({arr:[]})
        let headers=reactive({'token':sessionStorage.getItem("token")})
        let page=reactive({pageSize:"10",total:"20",page:"1"})//分页
        let searchlist=reactive({type:""})

         // 上传
        var fileList=ref()
        let beforeUpload=function(file){
            formData.data.file_src=file
        }
        var loading=ref(false)
        let handleChange=function(info){
            // console.log(info)
            if (info.file.status === 'uploading') {
                loading.value = true;
                return;
            }else if(info.file.status === 'done'){
                loading.value = false;
                console.log(info)
                // fileList.value=info
                formData.data.file_src=info.file.response.data.file_src_oss
            }
        }

         // 多选
        let selectedRowKeys=ref()
        let onSelectChange=function(selected) {
            selectedRowKeys.value = selected;
        }

          // 删除
        let delMore=function(){
            var id=selectedRowKeys.value.join(",")
            del(id)
        }
        let del=function(id){
            Modal.confirm({
                title: '你确定要删除吗？',
                icon: createVNode(ExclamationCircleOutlined),
            onOk() {
                proxy.$http.post('/admin/delapp',{ids:id},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            },
            onCancel() {
             console.log('Cancel');
            }
            })
            
        }

        // 搜索
        let search=ref()
        let toSearch=function(){
            page.page="1"
            getTabledata()
        }
          // 列表
        let getTabledata=function(){
            proxy.$http.post('/admin/applist',{page:page.page,pagesize:page.pageSize,search:search.value,type:searchlist.type},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data.data
                    page.total=res.data.data.total
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()

        // 添加、编辑
        let formData=reactive({data:{title:"",type:1,file_src:"",app_version:""}})
        let title=ref("")
        let visible=ref(false)
        let openAdd=function(){
            formData.data.title=""
            formData.data.type=1
            formData.data.file_src=""
            formData.data.app_version=""
            title.value="添加App版本"
            visible.value=true
        }
         let openEdit=function(id){
            proxy.$http.post('/admin/apppage',{id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    visible.value=true
                    title.value="编辑App版本"
                    formData.data=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }

        let handleOk=function(){
            if(title.value=="添加App版本"){
                proxy.$http.post('/admin/addapp',{title: formData.data.title,file_src: formData.data.file_src,type: formData.data.type,app_version:formData.data.app_version},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }else if(title.value=="编辑App版本"){
                proxy.$http.post('/admin/updateapp',{id:formData.data.id,title:formData.data.title,type:formData.data.type,app_version:formData.data.app_version,
                file_src:formData.data.file_src},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }
            
        }


         // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
            getTabledata()
        }
        return{
            handleChange,headers,
            visible,formData,openAdd,title,handleOk,openEdit,
            getTabledata,toSearch,searchlist,
            onSelectChange,delMore,del,
            columns,dataList,pageChange,page
        }
    }
}
</script>