
<template>
    <a-card title="节点管理" :bordered='false'>

        <div class="table-wrap">
            <div class="btn-wrap">
                <div class="btn-list">
                    <a-button type="primary" @click="openAdd(0)"><template #icon><PlusOutlined /></template>添加节点</a-button>
                    <!-- <a-button ><template #icon><GatewayOutlined /></template>线索领取</a-button>
                    <a-button ><template #icon><ApartmentOutlined /></template>线索分配</a-button> -->
                    <a-button type="danger" @click="delMore"><template #icon><DeleteOutlined /></template>删除</a-button>
                </div>
                <div>
                    <a-input-group compact>
                        <a-input v-model:value="search" placeholder="请输入节点名称"/>
                        <a-button type="primary">搜索</a-button>
                    </a-input-group>
                </div>
                
            </div>
            <a-table :pagination="false" :columns="columns" :data-source="dataList.arr" row-key="id"
                :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            >
                <template #bodyCell="{ column,record}">
                    <template v-if="column.dataIndex === 'icon'">
                        <component v-if="record.icon&&record.icon!='/'" :is="record.icon" />
                    </template>
                    <template v-if="column.dataIndex === 'menu_status'">
                        <a-switch @change="checked => changeStatus(checked, record.id)" :checkedValue="1" :unCheckedValue="0" checked-children="是" un-checked-children="否" v-model:checked="record.menu_status" />
                    </template>
                    <template v-if="column.dataIndex === 'operation'">
                        <a-button type="link" @click="openAdd(record.id)">添加子节点</a-button>
                        <a-button type="link" @click="openEdit(record.id)">编辑</a-button>
                        <a-button type="link" @click="del(record.id)">删除</a-button>
                    </template>
                </template>
            </a-table>

             <!-- <a-pagination
                v-model:pageSize="page.pageSize"
                show-size-changer
                :total="page.total"
                @change="pageChange"
                :show-total="total => `共 ${total} 条数据`"
            /> -->
        </div>

        <!-- 添加、编辑节点 -->
        <a-modal v-model:visible="visible" :title="title" @ok="handleOk" >
             <a-form
                :model="formData.data"
                :label-col="{ span: 5 }"
                :wrapper-col="{ span: 16 }"
                autocomplete="off"
            >
                <a-form-item label="上级节点" name="pid" >
                    <a-input placeholder="上级节点" v-model:value="formData.data.pid" />
                </a-form-item>
                <a-form-item label="节点名称" name="title" >
                    <a-input placeholder="节点名称" v-model:value="formData.data.title" />
                </a-form-item>
                <a-form-item label="节点（链接）" name="href" >
                    <a-input placeholder="节点（链接）例：/blacklist" v-model:value="formData.data.href" />
                </a-form-item>
                <a-form-item label="显示在菜单" name="menu_status" >
                    <a-radio-group v-model:value="formData.data.menu_status">
                        <a-radio :value="1">显示</a-radio>
                        <a-radio :value="0">不显示</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item label="图标" name="icon" >
                    <a-input placeholder="图标 例：RobotFilled" v-model:value="formData.data.icon" />
                </a-form-item>
                <a-form-item label="排序" name="sort" >
                    <a-input placeholder="排序，数字越小越靠前" v-model:value="formData.data.sort" />
                </a-form-item>

            </a-form>
        </a-modal>
    </a-card>
</template>

<script>
import { reactive,ref } from 'vue';
import {ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { getCurrentInstance ,createVNode} from 'vue'
import { message ,Modal} from 'ant-design-vue';
const columns = [
  {title: 'ID',dataIndex: 'id'},
  {title: '节点名称',dataIndex: 'title'},
  {title: '路径(链接)',dataIndex: 'href'},
  {title: '图标',dataIndex: 'icon'},
  {title: '是否显示菜单',dataIndex: 'menu_status'},
  {title: '排序',dataIndex: 'sort'},
  {title: '操作',dataIndex: 'operation',width:280},
];

export default {
    setup(){
        let dataList=reactive({arr:[]})
 
        const { proxy } = getCurrentInstance()
        let page=reactive({pageSize:"10",total:"20"})//分页

        // 列表
        let getTabledata=function(){
            proxy.$http.post('/admin/nodelist',{},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    dataList.arr=res.data.data
                 
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getTabledata()
        
         // 添加、编辑
        let formData=reactive({data:{pid:"0",title:"",href:"",menu_status:1,icon:"",sort:""}})
        let visible=ref(false)
        let title=ref("添加节点")
        let openAdd=function(value){
            formData.data.pid=value
            formData.data.title=""
            formData.data.href=""
            formData.data.menu_status=1
            formData.data.icon=""
            formData.data.sort=""
            visible.value=true
            title.value="添加节点"
        }

        let openEdit=function(id){
            proxy.$http.post('/admin/nodepage',{id:id},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    visible.value=true
                    title.value="编辑节点"
                    formData.data=res.data.data.data
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        let handleOk=function(){
            if(title.value=="添加节点"){
                proxy.$http.post('/admin/addnode',{pid:formData.data.pid,title:formData.data.title,href:formData.data.href,
                menu_status:formData.data.menu_status,icon:formData.data.icon,sort:formData.data.sort},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }else if(title.value=="编辑节点"){
                proxy.$http.post('/admin/updatenode',{id:formData.data.id,pid:formData.data.pid,title:formData.data.title,href:formData.data.href,
                menu_status:formData.data.menu_status,icon:formData.data.icon,sort:formData.data.sort},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }
        }

        let changeStatus=function(checked,id){

            proxy.$http.post('/admin/updatenode',{id:id,pid:formData.data.pid,menu_status:checked},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        visible.value=false
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
        }

        // 删除
        let delMore=function(){
            var id=selectedRowKeys.value.join(",")
            del(id)
        }
        let del=function(id){
            Modal.confirm({
                title: '你确定要删除吗？',
                icon: createVNode(ExclamationCircleOutlined),
            onOk() {
                proxy.$http.post('/admin/delnode',{ids:id},{
                    headers:{
                        'token':sessionStorage.getItem("token")
                    }
                }).then((res) => {
                    if(res.data.status==200){
                        message.success(res.data.msg)
                        getTabledata()
                    }else{
                        message.error(res.data.msg);
                    }
                });
            },
            onCancel() {
             console.log('Cancel');
            }
            })
            
        }

        // 多选
        let selectedRowKeys=ref()
        let onSelectChange=function(selected) {
            selectedRowKeys.value = selected;
            console.log(selectedRowKeys.value )
        }
        // 搜索
        let search=ref()

        // 分页
        let pageChange=function(current, size){
            page.pageSize=size
            page.page=current
            getTabledata()
        }
        return {
            search,
            formData,visible,title,handleOk,openAdd,openEdit,changeStatus,
            dataList,columns,
            page,pageChange,
            onSelectChange,selectedRowKeys,del,delMore
        };
    }
}
</script>