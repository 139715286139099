<template>
    <div>
        <div class="login">
            <img class="login-bg" src="../assets/bg1.png" alt="">

            <div class="login-logo">
                <h2>慢阻肺VR调控平台管理系统</h2>
            </div>

            <section class="form-wrap" >
                <a-tabs v-model:activeKey="activeKey" centered size="large" @change="toRegister">
                    <a-tab-pane key="1" tab="登录">
                        <div style="margin-top:50px">
                            <div class="input-wrap">
                                <mobile-two-tone two-tone-color="#5eb85e" style="font-size:22px"/>
                                <a-input v-model:value="formData.username" placeholder="请输入您的手机号或者用户名" />
                            </div>
                            <div class="input-wrap">
                                <unlock-two-tone two-tone-color="#5eb85e" style="font-size:22px"/>
                                <a-input-password  v-model:value="formData.password" placeholder="请输入您的密码" />
                            </div>
                            <div class=" verfig-wrap">
                                <div class="input-wrap">
                                    <a-input v-model:value="state.verify" placeholder="请输入验证码" />
                                </div>
                                <vueImgVerify ref="verifyRef" />

                            </div>
                            
                            <div class="login-info">
                                <a-button type="link" :size="size">忘记密码？</a-button>
                                <a-button type="primary" shape="round" @click="toLogin"> 登录 </a-button>
                            </div>
                           
                        </div>
                    </a-tab-pane>
                    <a-tab-pane key="2" tab="注册" force-render>
                    </a-tab-pane>
                </a-tabs>
            </section>
            <div class="login-form">
                
            </div>
        </div>
    </div>
</template>
<script>
import vueImgVerify from "../components/verify.vue"; 
import {reactive, ref} from 'vue'
import {useRouter} from "vue-router"
import { message } from 'ant-design-vue';
import { getCurrentInstance } from 'vue'
export default {
    components: {
        vueImgVerify,
    },
    setup(){
        const router=useRouter()
        const { proxy } = getCurrentInstance()
        // 登录
        var formData=reactive({username:"",password:""})
        const verifyRef = ref(null)
        const state = reactive({
            verify: "",
        }); 
        const toLogin = function() {
            console.log(verifyRef.value.imgCode);
            console.log(state.verify.toUpperCase());
            if (verifyRef.value.imgCode == state.verify.toUpperCase()) {
                proxy.$http.post('/admin/login',{username:formData.username,password:formData.password},{

                }).then((res) => {
                    if(res.data.status==200){
                        sessionStorage.setItem("token",res.data.data.token)
                        sessionStorage.setItem("roleid",res.data.data.admin_role_id)
                        sessionStorage.setItem("username",res.data.data.username)
                        router.push({path:"/"})
                    }else{
                        message.error(res.data.msg);
                    }
                });
            } else {
                message.error('验证码错误');
            }
        };


        var activeKey=ref("1") 
        let toRegister=function(){
           
            if(activeKey.value=="2"){
                router.push({path:"/register"})
            }
        }
        
        return{
            toLogin,formData,toRegister,
            activeKey,verifyRef,state
        }
    }
}
</script>