<template>
    <div>
        <a-card title="VR训练" :bordered='false'>

        </a-card>
    </div>
</template>

<script>
export default {
    
}
</script>