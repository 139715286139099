<template>
    <div>
        <div class="login">
            <img class="login-bg" src="../assets/bg1.png" alt="">

            <div class="login-logo">
                <h2>慢阻肺VR调控平台管理系统</h2>
            </div>

            <section class="form-wrap" >
                <a-steps v-model:current="current">
                    <a-step title="基本信息" > </a-step>
                    <a-step title="设置密码" />
                    <a-step title="注册完成"  disabled />
                </a-steps>


                <div style="margin-top:50px" class="register">
                    <section v-if="current==0">
                        <div class="input-wrap">
                            <idcard-two-tone two-tone-color="#5eb85e" style="font-size:22px"/>
                            <a-input v-model:value="formData.username" placeholder="请输入您的真实姓名" />
                        </div>
                        <div class="input-wrap">
                            <mobile-two-tone two-tone-color="#5eb85e" style="font-size:22px"/>
                            <a-input v-model:value="formData.telephone" placeholder="请输入您的手机号" />
                        </div>
                        <div class="input-wrap">
                            <a-radio-group v-model:value="formData.education">
                                <a-radio :value="1">专科</a-radio>
                                <a-radio :value="2">本科</a-radio>
                                <a-radio :value="3">研究生</a-radio>
                                <a-radio :value="4">博士</a-radio>
                            </a-radio-group>
                        </div>
                        <div class="input-wrap">
                            <book-two-tone two-tone-color="#5eb85e" style="font-size:22px"/>
                            <a-input v-model:value="formData.major" placeholder="请输入您的专业" />
                        </div>

                        <a-button class="register-btn" @click="current=1" type="primary" block>下一步</a-button>
                    </section>

                    <section v-if="current==1">
                         <div class="input-wrap">
                            <unlock-two-tone two-tone-color="#5eb85e" style="font-size:22px"/>
                            <a-input-password v-model:value="formData.password" placeholder="请输入您的密码" />
                        </div>
                        <div class="input-wrap">
                            <unlock-two-tone two-tone-color="#5eb85e" style="font-size:22px"/>
                            <a-input-password v-model:value="formData.repassword" placeholder="确认密码" />
                        </div>
                        <a-button class="register-btn" type="primary" @click="toRegister" block>提交</a-button>
                    </section>

                    <section v-if="current==2">
                        <a-result
                            status="success"
                            title="注册成功!"
                        >
                            <template #extra>
                                <a-button @click="toLogin" key="console" type="primary">立即登录</a-button>
                            </template>
                        </a-result>
                    </section>
                    
                   
                    <!-- <div class="login-info">
                        <a-button type="link" :size="size">忘记密码？</a-button>
                        <a-button type="primary" shape="round" > 注册 </a-button>
                    </div> -->
                </div>
            </section>

        </div>
    </div>
</template>
<script>
import {reactive, ref} from 'vue'
import { getCurrentInstance } from 'vue'
import { message } from 'ant-design-vue';
import {useRouter} from "vue-router"
export default {
    setup(){
        var current=ref(0) 
        const { proxy } = getCurrentInstance()

        var formData=reactive({username:"",telephone:"",education:"",major:"",password:"",repassword:""})

        let toRegister=function(){
            if(formData.password==""){
                message.error("请设置密码！");
            }else if(formData.password!=formData.repassword){
                message.error("两次密码不一致！");
            }else{
                proxy.$http.post('/admin/register',{username:formData.username,telephone:formData.telephone,
                education:formData.education,major:formData.major,password:formData.password},{

                }).then((res) => {
                    if(res.data.status==200){
                        current.value=2
                    }else{
                        message.error(res.data.msg);
                    }
                });
            }
           
        }

        const router=useRouter()
        let toLogin=function(){
            router.push({path:"/login"})
        }
        return{
            formData,toRegister,toLogin,
            current
        }
    }
}
</script>