<template>
    <div>
        <a-card title="个人中心" :bordered='false'>
            <a-row class="btn-wrap" :gutter="30">
                <a-col :span="11" :offset="1">
                    <a-avatar :size="80">
                        <a-icon :size="50"><user-outlined :style="{ fontSize: '30px'}" /></a-icon>
                    </a-avatar>
                    <div style="margin-top:40px" class="pwdcontent">
                        
                        <a-descriptions size="large" title="基本信息" :column="1" >
                            <a-descriptions-item laba-class-name="my-content"  label="用户名">{{userinfo.data.username}}</a-descriptions-item>
                            <a-descriptions-item laba-class-name="my-content"  label="学历">
                                <span v-if="userinfo.data.education==1">专科</span>
                                <span v-if="userinfo.data.education==2">本科</span>
                                <span v-if="userinfo.data.education==3">研究生</span>
                                <span v-if="userinfo.data.education==4">博士</span>
                            </a-descriptions-item>
                            <a-descriptions-item laba-class-name="my-content"  label="专业">{{userinfo.data.major}}</a-descriptions-item>
                            <a-descriptions-item laba-class-name="my-content"  label="角色">{{userinfo.data.title_admin_role}}</a-descriptions-item>

                            <a-descriptions-item laba-class-name="my-content"  label="手机号码">{{userinfo.data.telephone}}</a-descriptions-item>
                            <a-descriptions-item laba-class-name="my-content"  label="登录密码">
                                <a-button v-if="ispwd" link type='primary' @click="openPwd"><a-icon ><Edit /></a-icon> 点击修改</a-button>
                                <a-row v-if="ispwd==false" :gutter="20">
                                    <a-col :span="12">
                                        <a-input v-model="password" type="password" placeholder="输入新密码"></a-input>
                                    </a-col>
                                    <a-col :span="5">
                                        <a-button type='primary' @click="confrimPwd"> <template #icon>
                                            <check-outlined />
                                        </template></a-button>
                                    </a-col>
                                    <a-col :span="5">
                                        <a-button type='danger' @click="ClosePwd">
                                            <template #icon>
                                                <close-outlined />
                                            </template>
                                        </a-button>
                                    </a-col>
                                        
                                </a-row>
                            </a-descriptions-item>
                        </a-descriptions>
                    </div>
                    
                    
                </a-col>
                <a-col :span="12">

                </a-col>
            </a-row>
        </a-card>
    </div>
</template>

<script>
import { reactive,ref } from '@vue/reactivity'
import { getCurrentInstance } from 'vue'
export default {
    setup(){
        const { proxy } = getCurrentInstance()
        let userinfo=reactive({data:{}})

        var id=ref()
        let getUserifo=function(){
            proxy.$http.post('/admin/adminuserpage',{},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    userinfo.data=res.data.data.data
                    id.value=res.data.data.data.id
                }else{
                    message.error(res.data.msg);
                }
            });
        }
        getUserifo()

        // 编辑
        let ispwd=ref(true)
        let openPwd=function(){
            ispwd.value=false
        }
        let ClosePwd=function(){
            ispwd.value=true
        }
        let password=ref()
        let confrimPwd=function(){
            proxy.$http.post('/admin/updateadminuser',{id:id.value,password:password.value},{
                headers:{
                    'token':sessionStorage.getItem("token")
                }
            }).then((res) => {
                if(res.data.status==200){
                    ispwd.value=true
                    ElMessage({
                        message: res.data.msg,
                        type: 'success',
                    })
                }else{
                    ElMessage({
                        message: res.data.msg,
                        type: 'error',
                    })
                }
            });
        }

        return{
            userinfo,
            ispwd,openPwd,ClosePwd,confrimPwd
        }
    }
}
</script>