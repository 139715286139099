// export default class SocketService {
//     static instance = null;
//     static get Instance() {
//       if (!this.instance) {
//         this.instance = new SocketService();
//       }
//       return this.instance;
//     }
//     // 和服务端连接的socket对象
//     ws = null;
//     // 存储回调函数
//     callBackMapping = {};
//     // 标识是否连接成功
//     connected = false;
//     // 记录重试的次数
//     sendRetryCount = 0;
//     // 重新连接尝试的次数
//     connectRetryCount = 0;
//     //  定义连接服务器的方法
//     connect() {
//       // 连接服务器
//       if (!window.WebSocket) {
//         return console.log('您的浏览器不支持WebSocket');
//       }
//       // let token = $.cookie('123');
//       // let token = '4E6EF539AAF119D82AC4C2BC84FBA21F';
//       let url = 'ws://mzf.13524.net:7272';
//       this.ws = new WebSocket(url, 'echo-protocol');
//       // 连接成功的事件
//       this.ws.onopen = () => {
//         console.log('连接服务端成功了');
//         this.connected = true;
//         // 重置重新连接的次数
//         this.connectRetryCount = 0;
//       };
//       // 1.连接服务端失败
//       // 2.当连接成功之后, 服务器关闭的情况
//       this.ws.onclose = () => {
//         console.log('连接服务端失败');
//         this.connected = false;
//         this.connectRetryCount++;
//         setTimeout(() => {
//           this.connect();
//         }, 500 * this.connectRetryCount);
//       };
//       // 得到服务端发送过来的数据
//       this.ws.onmessage = msg => {
//         console.log(msg.data, '从服务端获取到了数据');
//       };
//     }
//     // 回调函数的注册
//     registerCallBack(socketType, callBack) {
//       this.callBackMapping[socketType] = callBack;
//     }
//     // 取消某一个回调函数
//     unRegisterCallBack(socketType) {
//       this.callBackMapping[socketType] = null;
//     }
//     // 发送数据的方法
//     send(data) {
//       // 判断此时此刻有没有连接成功
//       if (this.connected) {
//         this.sendRetryCount = 0;
//         try {
//           this.ws.send(JSON.stringify(data));
//         } catch (e) {
//           this.ws.send(data);
//         }
//       } else {
//         this.sendRetryCount++;
//         setTimeout(() => {
//           this.send(data);
//         }, this.sendRetryCount * 500);
//       }
//     }
//   }
  
// import { ElMessage } from 'element-plus'
 
let URL = 'wss://mzf.13524.net/wss'
let timer = null
let ws = null
let isConnect = false
let checkHeart = 'check-heart'
let count = 0
 
// 心跳检测
let heart = {
    timer: null,
    timeout: 20000, // 每20s进行一次心跳检测
    start() {
        this.timer = setTimeout(() => {
            if (isConnect) ws.send(JSON.stringify(checkHeart))
        }, this.timeout)
    },
    reset() {
        clearTimeout(this.timer)
        this.start()
    },
    close() {
        clearTimeout(this.timer)
    }
}
 
// WebSocket连接
const connectWebsocket = () => {
    if (count > 5) {
        connectCount()
        return
    }
    try { // 正在建立连接
        ws = new WebSocket(URL)
        initWebSocket() // 初始化WebSocket连接
    } catch { // 建立连接出错，重新连接
        connect()
    }
}
// 重新连接WebSocket
function connect() {
    if (isConnect) return
    console.log('尝试重新连接WebSocket')
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => { // 每次连接 延迟5s
        count++
        connectWebsocket()
    }, 5000)
}
// 初始化WebSocket连接
function initWebSocket() {
    ws.onopen = function () { // WebSocket连接成功
        isConnect = true
        // connectStatus(true)
        console.log('WebSocket连接成功')
    }
    ws.onerror = function () { // WebSocket连接发生错误
        isConnect = false
        // connectStatus(false)
        connect()
        heart.start() // 开始心跳检测
        console.log('WebSocket连接发生错误,正在尝试重新连接')
    }
    ws.onclose = function (e) { // 已关闭WebSocket连接
        isConnect = false
        // connectStatus(false)
        heart.close() // 关闭心跳检测
        console.log('已关闭WebSocket连接')
    }
    ws.onmessage = function (e) { // 接收到服务端发送的数据
        heart.reset() // 重置心跳检测
        if (e.data === checkHeart) return // 心跳检测
        receive(e.data)
    }
}
function connectCount() {
    console.log('WebSocket连接失败')
    clearTimeout(timer)
}
 
// 发送消息
const webSocketSend = (data) => {
    let code = ws?.readyState
    switch (code) {
        case 1:
            sendSock(data)
            break;
        case 2:
            console.log('WebSocket连接正在关闭中,请重新连接后再发送消息')
            break;
        default:
            console.log('请先建立WebSocket连接')
            break;
    }
}
function sendSock(data) {
    var login_data = '{"type":"login","client_name":"'+data+'","room_id":'+1+'}';
    ws.send(login_data)
    console.log('消息发送成功')
}
 
// 关闭连接
const closeWebSocket = () => {
    if (isConnection()) ws.close()
}
// 是否已连接
function isConnection() {
    return ws?.readyState === 1
}
 
// 自定义事件
var messageData={}
function receive(data) {
    var data = data;
    // messageData=data
    // switch(data['type']){
    //     // 服务端ping客户端
    //     case 'ping':
    //         ws.send('{"type":"pong"}');
    //         break;;
    //     // 登录 
    //     case 'login':
    //         console.log("登录成功");
    //         break;
    //     // 发言
    //     case 'say':
    //         console.log("消息详细内容");
    //         break;
    //     // 用户退出 更新用户列表
    //     case 'logout':
    //         console.log("退出");
    // }
    let event = new CustomEvent('receive', { detail: data })
    window.dispatchEvent(event)
}
function connectStatus(status) {
    let event = new CustomEvent('connectStatus', { detail: status })
    window.dispatchEvent(event)
}
 
export default {
    connectWebsocket,
    closeWebSocket,
    webSocketSend,
    isConnection,
    receive,
    // messageData
}
// import WebSocket from 'ws';

// class Websocket {
//   constructor(url, options = {}) {
//     this.url = url;
//     this.options = options;
//     this.ws = null;
//   }

//   connect() {
//     this.ws = new WebSocket(this.url, this.options);
//     this.ws.onopen = () => {
//       console.log('Websocket connection opened.');
//     };
//     this.ws.onmessage = (event) => {
//       console.log('Websocket message received.', event.data);
//     };
//     this.ws.onerror = (error) => {
//       console.error('Websocket error occurred.', error);
//     };
//     this.ws.onclose = () => {
//       console.log('Websocket connection closed.');
//     };
//   }

//   send(data) {
//     if (this.ws.readyState === WebSocket.OPEN) {
//       this.ws.send(data);
//     } else {
//       console.error('Websocket connection not open.');
//     }
//   }

//   close() {
//     this.ws.close();
//   }
// }

// export default Websocket;